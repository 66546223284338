
.search-card {
    margin-top: 1px;
    border-radius: 8px !important;
    padding: 1px;

    .Mui-checked {
        &:hover {
            background: unset !important;
        }
    }

    .search-card__more-btn {
        visibility: hidden;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        padding: 5px;
    }


    &:hover {
        background: $colorFCFAFF;
        cursor: pointer;

        .search-card__more-btn {
            visibility: visible;

            &:hover {
                background: #fff !important;
                border-radius: 4px;
            }
        }
    }

    .pulsating-circle {
        top: 8px;
        left: 8px;
    }
}

.avatar-pic {
    color: #999999 !important;
    background: #F5F5F5 !important;
    text-transform: uppercase !important;
}

.action-dropdown-icons {
    vertical-align: middle;
    margin-right: 15px;
}

.options-sub-menu {
    svg {
        width: 18px;
        height: 18px;
    }
}

.search-card-move-stage-btn {
    color: #FFFFFF !important;
    font-size: 14px !important;
    background: $color7F56D9 !important;
    padding: 6px 16px !important;
}

.selected-card {
    background: $colorFCFAFF !important;
    border: 2px solid $color7F56D9 !important;
    padding: 0;
}

.search-card-body {
    padding: 24px;
    border-top: 1px solid #F5F5F5;

    .search-card-divider {
        border: 1px solid #F5F5F5;
        margin: 0;
    }

    .search-card-item {
        margin-bottom: 10px;

        .MuiList-root {
            padding: 0;
        }

        .item-title {
            font-size: 13px;
            line-height: 150%;
            margin: 0px;

            .position, .current-position {
                font-weight: 600;
                color: #111111;
            }

            .com-contry {
                color: #111111;
                margin-right: 10px;
            }

            .period {
                color: #999999;
            }

            .MuiListItem-root {
                padding: 0;
                align-items: start;
            }

            .MuiListItemIcon-root {
                min-width: 25px;
                color: #111111;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            .MuiListItemText-primary {
                font-size: 13px;
                line-height: 1;
                margin: 0;
                font-weight: 600;
                color: #111111;
                overflow-wrap: break-word;
            }

            .MuiListItemText-secondary {
                p {
                    font-size: 13px;
                    line-height: 150%;
                    margin: 0;
                    color: #111111;
                    overflow-wrap: break-word;
                }

                .item-period {
                    font-size: 13px;
                    line-height: 150%;
                    margin: 4px 0 0;
                    color: #999999;
                    right: 0;
                }
            }
            .MuiListItemSecondaryAction-root {
                font-size: 13px;
                line-height: 150%;
                margin: 0;
                color: #999999;
                right: 0;
                top: 7px;
            }
            .MuiListItemText-multiline {
                margin-top: 0;
                margin-bottom: 16px
            }
        }

        .item-desc {
            color: #999999 !important;
        }
    }

    .salary-notice-period-experiance {
        display: flex;
        font-size: 13px;
        color: #111111;
        margin-bottom: 0;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0;
        gap: 0 10px;

        &>span {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 7px 12px;
            background: #f5f5f5;
            border-radius: 16px;
            margin-bottom: 7px;
            gap: 12px;

            img, svg {
                font-size: 16px;
                width: 16px;
                height: 16px;
            }
        }

    }
}

@media (max-width: 420px) {
    .search-card {
        .search-card__person {
            width: -webkit-fill-available;
        }

        .search-result-up-opt, .item-period-right-label {
            display: none;
        }

        .search-result-bottom-opt {
            display: flex;
        }
        .item-period-bottom-label  {
            display: block;
        }

        .MuiListItemText-primary {
            max-width: 100%;
        }
        .MuiListItemText-secondary {
            p {
                max-width: 100%;
            }
        }

    }
}

@media (min-width: 421px) and (max-width: 767px) {
    .search-card {
        .search-card__person {
            width: calc(100% - 160px);
        }

        .search-result-up-opt, .item-period-right-label {
            display: block;
        }

        .search-result-bottom-opt, .item-period-bottom-label {
            display: none;
        }

        .MuiListItemText-primary {
            max-width: calc(100% - 140px);
        }
        .MuiListItemText-secondary {
            p {
                max-width: calc(100% - 140px);
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1400px) {
    .search-card {
        .search-card__person {
            width: -webkit-fill-available;
        }

        .search-result-up-opt, .item-period-right-label {
            display: none;
        }

        .search-result-bottom-opt {
            display: flex;
        }
        .item-period-bottom-label  {
            display: block;
        }

        .MuiListItemText-primary {
            max-width: 100%;
        }
        .MuiListItemText-secondary {
            p {
                max-width: 100%;
            }
        }
    }
}

@media (min-width: 1400px) {
    .search-card {
        .search-card__person {
            width: calc(100% - 160px);
        }

        .search-result-up-opt, .item-period-right-label {
            display: block;
        }

        .search-result-bottom-opt, .item-period-bottom-label {
            display: none;
        }

        .MuiListItemText-primary {
            max-width: calc(100% - 140px);
        }
        .MuiListItemText-secondary {
            p {
                max-width: calc(100% - 140px);
            }
        }
    }
}