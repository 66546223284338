.MuiDialog-paper {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
    border-radius: 12px !important;

    .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 10px;
        font-size: 20px;
        border-radius: 8px;
        color: $color667085;

        &:hover {
            background: $colorF9FAFB;
            cursor: pointer;
        }
    }
}

.common-modal-wrapper {
    padding: 24px 24px 8px;
    box-sizing: border-box;

    .modal-icon {
        display: flex;
        align-items: center;
        background: $colorF4EBFF;
        border: 8px solid $colorF9F5FF;
        color: $color7F56D9;
        padding: 8px;
        font-size: 20px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: $color101828;
        margin-top: 15px;
        margin-bottom: 4px;
    }

    .description {
        font-size: 14px;
        line-height: 20px;
        color: $color667085;
        margin: 0;

        .count {
            font-weight: bold;
        }
    }

    .modal-loading-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
    }

    .common-modal-content {
        margin-top: 20px;
    }

    .input-field-with-icon-label-wrapper,
    .select-option-from-many-wrapper,
    .chips-input-wrapper,
    .phone-wrapper,
    .upload-wrapper,
    .salary-range-wrapper,
    .image-title-sub-title-wrapper,
    .async-autocomplete-wrapper {
        margin-top: 20px;
    }

    .delete-record-link {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorF04438;
        cursor: pointer;
        width: fit-content;
        margin-top: 20px;
        margin-bottom: 0;
    }
}

.modal-action-wrapper {
    position: sticky;
    bottom: 0;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    padding: 24px !important;
    background: $colorFFFFFF;
    z-index: 1;

    .main-action-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        width: 100%;
    }

    button {
        width: 50%;
        margin: 0 !important;
    }
}

.modal-action-form-wrapper {
    padding-left: 0px !important ;
    padding-right: 0px !important;
}

.max-width-408 {
    .MuiDialog-paper {
        max-width: 408px;
    }
}

.max-width-480 {
    .MuiDialog-paper {
        max-width: 480px;
    }
}

.max-width-560 {
    .MuiDialog-paper {
        max-width: 560px;
    }
}

.max-width-640 {
    .MuiDialog-paper {
        max-width: 640px;
    }
}

.max-width-650 {
    .MuiDialog-paper {
        max-width: 650px;
    }
}

.max-width-720 {
    .MuiDialog-paper {
        max-width: 720px;
    }
}

