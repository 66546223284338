.adding-company-user-or-team-member-wrapper {
    .cus-label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-top: 20px;
        margin-bottom: 6px;
    }

    .add-user-wrapper {
        position: relative;
        display: flex;
        gap: 10px;
        align-items: end;

        .input-field-with-icon-label-wrapper,
        .select-option-from-many-wrapper {
            margin-top: 0;
            margin-bottom: 10px;
        }

        .delete-user {
            display: none;
            position: absolute;
            top: -7px;
            left: -5px;
            z-index: 1;
            padding: 5px;
            background: $colorF4EBFF;
            border-radius: 50%;
            cursor: pointer;
        }

        &:hover {
            .delete-user {
                display: block;
            }
        }
    }
    
    .add-another-user {
        display: flex;
        align-items: center;
        width: fit-content;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color6941C6;
        gap: 8px;
        cursor: pointer;
        margin-top: 2px;
        margin-bottom: 0;
    }
}