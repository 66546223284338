.list-page-header-fixed {
    position: sticky;
    display: flex;
    align-items: center;
    padding: 40px 0 16px 0;
    width: 100%;
    background: $colorF5F5F5;
    box-sizing: border-box;
    top: 72px;
    z-index: 3;

    .page-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .title-wrapper {
            white-space: nowrap;
            margin-right: 15px;
        }

        .actions-wrapper {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
}

@media (max-width: 1360px) {
    .list-page-header-fixed {

        .page-header-wrapper {
            display: block;
            
            .actions-wrapper {
                margin-top: 15px;
                justify-content: flex-end;
            }
        }
    }
}

.filter-btn {
    background: $colorFFFFFF !important;
    border: 1px solid $colorD0D5DD !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px !important;
    height: 40px;
    width: fit-content;
    white-space: nowrap;
    color: $color344054 !important;
    
    svg {
        color: $color667085 !important;
    }

    &:hover {
        background: $colorF9FAFB !important;
    }

    @media(max-width: 575px) {
        .non-mobile-btn-name {
            display: none
        }

        .MuiButton-startIcon {
            margin-right: 0;
        }
    }
}

.filter-status-proper {
    z-index: 4;

    ul {
        li {
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            min-height: 40px;
            padding-left: 15px;
            padding-right: 15px;

            label {
                margin-left: 0;
                margin-right: 20px;
            }

            span {
                font-size: 13px;
            }

            .filters__count {
                float: right;
            }

            .check-box-wrapper {
                margin-right: 10px;
            }
        }
    }

    .filter-radio-menu-list{
        .MuiFormControlLabel-labelPlacementEnd {
            margin: 0;
            padding-right: 8px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }

}

@media (max-width: 1100px) {
    .non-mobile-new-project-btn {
        display: none !important
    }
}

.bulk-action-btn {
    background: $colorFFFFFF !important;
    border: 1px solid $colorD0D5DD !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px !important;
    color: $color344054 !important;

    @media(max-width: 575px) {
        .non-mobile-btn-name {
            display: none
        }

        .MuiButton-endIcon {
            margin: 0 !important;
        }
    }
}

.bulk-action-proper {
    z-index: 3;

    ul {
        li {
            min-height: 35px;
            font-size: 13px !important;
            min-width: 160px;

            span {
                font-size: 13px !important;
            }

            svg {
                width: 16px;
                height: 16px;
                color: $color111111;
            }
        }
    }

}


