.error-page-info-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 368px;
    min-width: 300px;

    .error-page-404{
        font-weight: 900;
        font-size: 100px;
        line-height: 100px;
        text-align: center;
        color: $color412D6B;
        margin-top: 24px;
        margin-bottom: 0px;
    }

    .error-page-title {
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: $color101828;
        margin-top: 10px;
        margin-bottom: 8px;
    }

    .error-page-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $color667085;
        margin-top: 0px;
        margin-bottom: 32px;
    }

    .btn-wrapper {
        display: flex;
        justify-content: center;
    }
}
