.purchase-credit-modal {
    .credit-counter-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        margin-top: 25px;

        .count {
            font-size: 72px;
            line-height: 90px;
            font-weight: 600;
            text-align: center;
            letter-spacing: -0.02em;
            color: $color101828;
            min-width: 50px;
        }

        .inc-dec-btn {
            font-size: 26px !important;
            width: 44px;
            height: 44px;
            min-width: unset;
        }
    }

    .divider {
        border: 0;
        border-top: 1px solid $colorEAECF0;
    }

    .payment-info-table {
        width: 100%;

        tr {
            td:first-child {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                color: $color101828;
            }

            td:nth-child(2) {
                text-align: right;
                font-size: 16px;
                line-height: 24px;
                color: $color475467;
            }
        }
    }

    .modal-action-wrapper {
        .main-action-wrapper {
            button:first-child {
                width: 30%;
            }

            button:nth-child(2) {
                width: 70%;
            }

            .modal-secondary-btn {
                width: 66% !important;
            }
        }

    }
}
