.group-of-avatar-title-sub-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .group-avatar-wrapper {
        img {
            object-fit: contain !important;
            background: $colorF9F5FF;
        }

        .user-avatar {
            img {
                object-fit: cover !important;
                border-radius: 50%;
            }
        }

        .MuiAvatar-root {
            background: $colorF4EBFF;
            color: $color7F56D9;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .name-wrapper {
        margin-left: 8px;

        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 15.4px;
            color: $color111111;
            margin: 0;
        }

        .sub-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 13.2px;
            color: $color667085;
            margin: 0;
        }
    }
}

.MuiTableCell-root {
    .group-avatar-wrapper {
        justify-content: center;
    }
}