.filter-bar {
    padding: 0 3px;
    height: calc(100vh - 170px);
    overflow-y: auto;
    box-sizing: border-box;

    .MuiSlider-root {
        width: 88%;
        margin: auto;
        display: block;
    }

    .filters__body {
        .search-keyword {
            .MuiInputBase-root {
                input {
                    padding: 12px 10px !important;
                    border: 1px solid #D0D5DD;
                    height: 44px;
                    background: #ffffff;
                    box-sizing: border-box;
                    border-radius: 8px;

                    &:focus {
                        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $colorF4EBFF;
                        outline: none !important;
                    }
                }
            }
        }

        fieldset {
            border: none;
        }
    }

    .filters__body .MuiSlider-thumb::after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .MuiAutocomplete-input {
        cursor: pointer;
    }

    .MuiChip-root {
        height: 40px;
        border-radius: 8px;
        padding: 8px 5px;
        background-color: #eeeeee;
    }
}

.MuiAutocomplete-option {
    font-size: 13px;
    line-height: 16px;
    color: #111111;
}

.search-filter-drawer {
    z-index: 1299 !important;

    .MuiDrawer-paper {
        max-width: 375px;

        .makeStyles-filterRoot-16 {
            width: 100%;
            box-sizing: border-box;
        }
    }
}

@media (max-width: 959px) {
    .filter-bar {
        height: 100%;
        padding-bottom: 50px !important;
    }
}
