.all-company-users-page {
    .stats-widget-container {
        .stats-widget-wrapper {
          width: 428px;
        }
      }
}

#team-list-table-container {
    max-height: calc(100vh - 370px);
}

.common-modal-wrapper {
    .company-user-resend-invitation {
        margin-top: 6px;
        width: fit-content;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color7F56D9;
        cursor: pointer;
    }
}