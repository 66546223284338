.mobile-fab-btn {
    display: none !important;
    position: fixed !important;
    bottom: 75px;
    right: 15px;
    line-height: unset !important;

    svg {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 1099px) {
    .mobile-fab-btn {
        display: flex !important;
    }
}

.non-mobile-fab-btn {
    position: fixed !important;
    bottom: 100px;
    right: 15px;
    line-height: unset !important;
    z-index: 999;
}
