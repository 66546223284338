select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    background: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
        #ffffff no-repeat calc(100% - 12px) !important;
    cursor: pointer;
}

::placeholder {
    color: $color667085 !important;
}

// Hide Arrows From Input Number
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// Firefox
input[type="number"] {
    -moz-appearance: textfield;
}

.input-wrapper.multi-input-row {
    .input-field-with-icon-label-wrapper {
        margin-top: 0;
    }
}

/* input fields */
.input-field-with-icon-label-wrapper {
    position: relative;

    label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-bottom: 8px;
    }

    .input-wrapper {
        position: relative;

        .icon {
            svg {
                position: absolute;
                top: 12px;
                left: 12px;
                color: $color667085;
                width: 20px;
                height: 20px;
            }

            &.active {
                svg {
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    color: $color667085;
                    background: $colorF9F5FF;
                    padding: 11px;
                    border-radius: 8px 0 0 8px;
                    width: 20px;
                    height: 20px;

                    path {
                        stroke: unset;
                    }
                }

                .non-colored-icons {
                    svg {
                        path {
                            stroke: currentColor;
                        }
                    }
                }
            }
        }

        .field {
            background: $colorFFFFFF;
            border: 1px solid $colorD0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            min-height: 44px;
            width: 100%;
            box-sizing: border-box;
            font-size: 16px;
            line-height: 20px;
            color: $color101828;

            fieldset {
                border: 0;
            }

            input {
                padding: 0 !important;
            }

            .MuiOutlinedInput-root {
                padding: 0;
                gap: 5px;
                min-height: 44px;
            }

            &:focus,
            &:focus-within {
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $colorF4EBFF !important;
                outline: 0 !important;
            }
        }

        .date-picker {
            padding: 0px 12px;
        }

        textarea {
            padding: 9px;
        }

        .disabled {
            background: $colorF9FAFB !important;
        }
    }

    .multi-input-row {
        display: flex;
        grid-gap: 8px;
        margin-bottom: 10px;
    }
}

/* radio selections input */
.select-option-from-many-wrapper {
    label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-bottom: 8px;
    }

    button {
        border-radius: 8px;
        line-height: 20px;
        padding: 9px 16px !important;
        border-color: $colorD0D5DD !important;
        color: $color344054;
        font-weight: 500;
        background: $colorFFFFFF;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        height: 44px;

        .status-circle {
            margin-right: 7px;
            width: 10px;
            height: 10px;

            circle {
                fill: currentColor;
            }

            &.active {
                color: $color12B76A;
            }

            &.pending {
                color: $colorF79009;
            }

            &.inactive {
                color: $colorF04438;
            }

            &.placed {
                color: $colorAAAAAA;
            }
        }

        &:hover {
            background: $colorF9FAFB !important;
        }
    }

    .selected-btn {
        background: $colorF4EBFF !important;
        color: $color1D2939;

        &:hover {
            background: $colorF4EBFF !important;
        }
    }

    .Mui-disabled {
        background: $colorF9FAFB !important;
    }
}

/* chips input */
.chips-input-wrapper {
    label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-bottom: 8px;
    }

    .input-wrapper {
        border: 1px solid $colorD0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 5px 12px;
        min-height: 44px;
        box-sizing: border-box;

        .MuiOutlinedInput-root {
            padding: 0;
            gap: 5px;
        }

        .MuiAutocomplete-input {
            padding: 0 !important;
            height: 32px;
        }

        .MuiFormLabel-root {
            display: none;
        }

        .MuiOutlinedInput-notchedOutline {
            border: 0;
        }

        .MuiChip-root {
            height: 25px !important;
            border-radius: 8px;
            margin: 0;
        }

        .MuiChip-deleteIcon {
            font-size: 15px;
        }

        &:focus-within {
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $colorF4EBFF;
        }
    }
}

.async-close-btn {
    font-size: 16px !important;

    svg {
        width: 16px;
        height: 16px;
        color: $color98A2B3;
        margin-right: 3px;
    }
}

/* phone input */
.phone-wrapper {
    position: relative;

    label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-bottom: 8px;
    }

    .cus-phone-number {
        box-sizing: border-box;

        .flag-dropdown {
            background-color: unset !important;
            border: unset !important;

            .selected-flag {
                width: 45px !important;
                padding-left: 12px !important;

                &:hover,
                &:focus {
                    background-color: unset !important;
                }
            }
        }

        .input-field {
            height: 44px !important;
            width: 100% !important;
            border-radius: 8px;
            padding-left: 45px !important;
            border: 1px solid $colorD0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            font-size: 16px;

            &:focus {
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $colorF4EBFF;
            }
        }

        .country-list {
            width: max-content !important;
            scroll-behavior: auto !important;

            .search-box {
                margin-left: 0 !important;
                width: 97%;
            }

            @media (max-width: 575px) {
                width: 220px !important;
            }
        }
    }
}

// autocomplete
.async-autocomplete-wrapper {
    label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-bottom: 8px;
    }

    .input-wrapper {
        position: relative;
        border: 1px solid $colorD0D5DD;
        background: $colorFFFFFF;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 5px 12px;
        min-height: 44px;
        box-sizing: border-box;

        .icon {
            svg {
                position: absolute;
                top: 12px;
                left: 12px;
                color: $color667085;
                z-index: 1;
                width: 20px;
                height: 20px;
            }
        }

        .preview-selected-item-img {
            position: absolute;
            top: 50%;
            left: 13px;
            background: $colorF9F5FF;
            color: $color475467;
            transform: translate(-10%, -50%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            font-size: 12px;
            font-weight: 500;
            padding: 2px;
            z-index: 1;
        }

        .MuiFormLabel-root {
            display: none;
        }

        .MuiAutocomplete-input {
            padding: 0 !important;
        }

        .MuiOutlinedInput-notchedOutline {
            border: 0;
        }

        .MuiOutlinedInput-root {
            padding: 0;
            padding-left: 28px;
        }

        &:focus-within {
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $colorF4EBFF;
        }

        .MuiInputBase-root {
            gap: 6px;
            min-height: 32px;
            height: unset;
        }

        .MuiChip-root {
            background: $colorFFFFFF;
            border: 1px solid $colorD0D5DD;
            border-radius: 6px;
            margin: 0;
        }
    }

    .disabled {
        background: $colorF9FAFB;
        div {
            background: $colorF9FAFB;

            .MuiInputBase-root {
                background: $colorF9FAFB !important;
            }
        }
    }
}

.async-auto-no-results-wrapper {
    display: inline-flex;
    align-items: center;

    .message {
        font-size: 16px;
        line-height: 24px;
        color: $color101828;
    }

    a {
        color: $color7F56D9;
    }
}

.multi-select-chips-in-dropdown {
    border-radius: 0 !important;
    height: 44px !important;
    width: 100%;
    justify-content: left !important;
    background: inherit !important;
    padding: 5px 14px !important;
    gap: 8px;

    &:hover {
        background: $colorF9FAFB !important;
    }
}

.async-option-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 14px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    color: $color101828;

    .async-img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        border-radius: 50%;
        background: $colorF9F5FF;
        color: $color475467;
        font-size: 12px;
        font-weight: 500;
    }

    &:hover {
        background: $colorF9FAFB;
        cursor: pointer;
    }
}

.async-img-flag {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    object-fit: cover;
}

.MuiAutocomplete-popper {
    ::-webkit-scrollbar-track {
        background-color: #ffffff;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $colorEAECF0;
    }

    .MuiAutocomplete-option {
        &:hover {
            background: $colorF9FAFB !important;
        }
        
        &.Mui-focused {
            background: $colorF9FAFB !important;
        }
    }
}

.salary-range-wrapper {
    .label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-bottom: 8px;
    }
    
    .salary-wrapper {
        display: flex;
        align-items: end;
        gap: 7px;

        .input-field-with-icon-label-wrapper {
            margin-top: unset !important;
        }

        .currency-dropdown {
            margin-bottom: 10px;
            border-radius: 4px;
            padding: 5px 10px;
            margin-right: 7px;
            border: 1px solid #e5e5e5;
            width: 80px;
            height: 55px;
            background-color: #fff !important;
            cursor: pointer;

            &:hover {
                border: 1px solid #111111;
            }

            &:focus {
                outline: 0;
            }
        }

        .cus-field-container {
            padding: 0 10px;

            .MuiInputLabel-formControl {
                transform: translate(0, 19px) scale(1);
            }
        }
    }
}

.form-group {
    .field {
        &:focus,
        &:focus-within {
            background-color: #fff !important;

            .MuiInputBase-root {
                background-color: #fff !important;
            }

            .MuiInputBase-root input:focus {
                background-color: #fff !important;
            }
        }

        .MuiInputBase-root {
            &:hover {
                background-color: #fff !important;
            }
        }
    }
}

.cus-information {
    font-size: 13px;
    line-height: 16px;
    color: #aaaaaa;
    margin-top: 5px;
    margin-bottom: 5px;
}

.cus-switch-with-label {
    display: flex !important;
    justify-content: space-between;
    margin-left: 0 !important;
    margin-top: 25px;
    width: 100%;

    .MuiTypography-body1 {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }
}

.cus-dropdown-wrapper {
    position: relative;
    max-width: 400px;
}

@media (max-width: 575px) {
    .cus-dropdown-wrapper {
        max-width: 100%;
    }
}

.country-dropDowns-with-field {
    display: flex;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    .country-dropdown {
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .country-flag {
            display: flex;
            align-items: center;
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            border-right: 0 !important;
            width: 60px;
            height: 42px;
            padding: 5px 10px 5px 15px;
            background: #761ee414;
            box-sizing: border-box;

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .down-arrow {
            position: absolute;
            top: 13px;
            right: 20px;
            width: 16px;
            height: 16px;
            cursor: pointer;
        }
    }

    .country-dropdown.currency {
        width: 120px;
    }

    .country-name {
        font-weight: 500;
        border-radius: 0;
        border: 0;
        border-right: 0;
        border-left: 0;
        background: #761ee414;
        padding-left: 0;
        padding-right: 30px;
        height: 42px;
        width: 100%;
        font-size: 16px;
        outline: none;
        box-sizing: border-box;
    }

    .salary {
        padding: 15px 71px 15px 24px !important;
        height: 42px !important;
        width: calc(100% - 120px);
        text-align: right;
        background: #ffffff;
        font-size: 15px;
        line-height: 18px;
        border: 0;
        outline: none;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        box-sizing: border-box;
    }

    .months-label {
        position: absolute;
        right: 15px;
        top: 13px;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.02em;
    }

    &:focus-within {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $colorF4EBFF;
    }
}

.cus-field-titles {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #111111;
    margin-top: 25px;
    margin-bottom: 8px;
}

.selection-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .selection-option {
        background: $colorFFFFFF;
        border: 1px solid $colorD0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        height: 44px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        fieldset {
            border: 0;
        }
    }

    .selection-option.active {
        background: $colorF4EBFF;
    }

    @media (max-width: 575px) {
        .selection-option {
            width: 100%;
        }
    }
}

.cus-dropdown-list {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    margin: auto;
    max-width: 400px;
    border-radius: 4px;
    margin-top: -14px;
    max-height: 275px;
    overflow: auto !important;
    z-index: 1;

    .list-item {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 10px 15px;
        border-radius: 4px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
        }

        span {
            padding-left: 14px;
        }

        &:hover {
            background: #ebebeb;
        }
    }
}

.MuiMultiSectionDigitalClock-root {
    ::after {
        height : 0 !important;
    }
}




// .Mui-focusVisible {
//     background: #F9FAFB !important;
// }