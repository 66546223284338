.page-header-fixed {
    position: sticky;
    padding: 26px 0 15px 0;
    width: 100%;
    background: #f5f5f5;
    z-index: 3;
    box-sizing: border-box;
    top: 65px;
    height: 98px;

    .common-page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .common-page-title {
            position: relative;
            align-items: center;
        }
        
        .common-page-actions {
            display: flex;
            position: relative;
            align-items: center;
            gap: 10px;

            .group-avatar-wrapper {
                img {
                    object-fit: contain !important;
                    background: $colorF9F5FF;
                }

                .MuiAvatar-root {
                    background: $colorF9FAFB;
                    color: $color475467;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}

.MuiCheckbox-root {
    color: #111111 !important;

    .MuiSvgIcon-root {
        font-size: 1.33rem;
    }
}

.sticky-bars {
    position: sticky;
    padding: 52px 0 0 15px;
    background: #f5f5f5;
    z-index: 1;
    box-sizing: border-box;
    top: 65px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.stage-container {
    position: sticky;
    padding: 0;
    width: 100%;
    background: #f5f5f5;
    z-index: 3;
    box-sizing: border-box;
    top: 135px;
    border-bottom: 1px solid #E5E5E5;
    height: 48px;
}

@media (min-width: 576px) and (max-width: 959px) {
    .sticky-bars {
        top: 140px;
    }
}

@media (min-width: 576px) and (max-width: 959px) {
    .page-header-fixed {
        height: 75px;
    }
}

@media (max-width: 575px) {
    .page-header-fixed {
        padding: 20px 0 15px 0;
        height: 65px;
    }

    .stage-container {
        top: 121px;
    }
}

.MuiIconButton-root:hover {
    background-color: transparent !important;
    color: rgb(80, 79, 79);
}


