.integrations-wrapper {
    .page-name {
        margin-top: 40px;
        margin-bottom: 0;
    }

    .sec-heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: $color101828;
        margin-top: 40px;
        margin-bottom: 4px;
    }

    .sec-sub-heading {
        font-weight: 400;
        font-size: 13px;
        color: $color9B9B9B;
        margin-left: 6px;
    }

    .sec-desc {
        font-size: 14px;
        line-height: 20px;
        color: $color667085;
        margin-top: 0;
    }
}

.integration-ref-doc {
    display: inline-block;
    font-size: 14px;
    line-height: 150%;
    color: $color7F56D9;
    margin-top: 5px;
}

.integration-card {
    padding: 24px;
    border-radius: 8px !important;

    .MuiCardHeader-title {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        color: #111111;
        line-break: anywhere;
    }

    .MuiCardHeader-action {
        margin-top: 0;
    }

    .count-msg {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: #111111;
    }

    .sync-msg {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: #999999;
    }
}