.add-edit-candidate-container {
    .profile-container {
        .profile-content-container {
            width: 100%;
        }
    }

    .passport-id-comp {
        display: flex;
        width: fit-content;
        width: 100%;

        .cus-country-dropdown {
            width: 200px;

            .field {
                border-radius: 8px 0 0 8px;
                background: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
                    $colorF9F5FF no-repeat calc(100% - 12px) !important;
            }
        }

        .passport-number-input {
            label {
                visibility: hidden;
            }

            .field {
                border-radius: 0 8px 8px 0;
            }
        }
    }

    .sub-status-note {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
        color: $colorF79009;

        svg {
            width: 18px;
            height: 18px;
        }
    }
}
