.custom-container {
    width: 83.33%;
    max-width: 1440px;
    margin: auto !important;
}

@media (max-width: 575px) {
    .custom-container {
        width: calc(100% - 30px);
    }
}

@media (min-width: 576px) and (max-width: 1250px) {
    .custom-container {
        width: calc(100% - 60px);
    }
}





