.send-bulk-whatsapp-messages-modal {
    .whatsapp-stepper {
        margin-top: 5px;
        margin-bottom: 25px;

        .MuiStep-root {
            .Mui-active {
                color: $color6941C6;
            }

            .Mui-completed {
                color: $color344054;
            }

            .MuiStepLabel-label {
                margin-top: 6px;
                font-size: 13px;
            }
        }
    }

    .template-title {
        font-size: 12px;
        margin-bottom: -5px;
        font-weight: 300;
    }

    .template-container {
        background: #ffffff !important;
        border-radius: 8px;
        padding: 15px;
        margin-top: 10px;
        border: 1px solid #e5e5e5;
        margin-bottom: 8px;

        pre {
            text-wrap: wrap;
            margin: 0;
        }

        &.active {
            background: $colorFCFAFF !important;
            border: 2px solid $color7F56D9;
            padding: 14px;
        }

        &:hover {
            background: $colorFCFAFF !important;
            cursor: pointer;
        }

        &.ready-only {
            margin-top: 15px;
            background: $colorFCFAFF !important;
            overflow: scroll;

            &:hover {
                cursor: unset;
            }
        }
    }

    .params-heading {
        font-size: 14px;
        margin-top: 25px;
        margin-bottom: -5px;
        font-weight: 600;
    }

    .select-or-type-container {
        display: flex;
        align-items: end;
        gap: 5px;
        justify-content: space-between;

        & > div {
            width: 45%;
        }
    }
}
