.preview-card-modal {
    ::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        box-shadow: none;
        background-color: transparent;
    }

    .MuiPaper-elevation1 {
      border: 0;
    }

    .MuiDialog-paperFullScreen {
      width: 800px;
      margin: 15px;
      height: calc(100vh - 30px);
    }

    .view-project-sec {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 162px); 
      max-width: 1200px;
      margin: auto; 
      border-left: 1px solid #E5E5E5;
      border-right: 1px solid #E5E5E5;
    }

    .view-project-header {
      max-width: 1200px;
      margin: auto;
      border-left: 1px solid #E5E5E5;
      border-right: 1px solid #E5E5E5;
    }
  }

@media (max-width: 575px) {
  .preview-card-modal {
      .stage-btn-name-hide {
          display: none;
      }

      .MuiDialog-paperFullScreen {
        margin: 0;
        height: 100vh;
      }

      .MuiDialog-paper.MuiPaper-rounded {
          border-radius: 0 !important;
      }

      .view-project-header {
        border: 0;
      }
  }
}


