.upgrade-project-modal {
    .modal-action-wrapper {
        .main-action-wrapper {
            button:first-child {
                width: 35%;
            }

            button:nth-child(2) {
                width: 65%;
            }
        }

    }
}
