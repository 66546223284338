.scrollable-tabs {
    .MuiTab-root {
        min-width: auto !important;
        text-transform: none !important;
        font-size: 13px !important;
        color: #aaaaaa;
        font-weight: normal;

        &:hover {
            color: #111111;

            .tab-count {
                background: #e5e5e5;
                border-radius: 4px;
                color: #111111;
            }
        }
    }

    .tab-count {
        font-size: 10px;
        line-height: 150%;
        color: #aaaaaa;
        padding: 3px 6px;
        margin-left: 6px;
        font-weight: normal;
    }

    .Mui-selected {
        font-weight: bold;

        .tab-count {
            background: #e5e5e5;
            border-radius: 4px;
            color: #111111;
        }

        &:hover {
            color: $color7F56D9;
        }
    }
}

.scrollable-tabs.candidate-edit-page {
    margin-bottom: 25px;

    .MuiTabs-scrollable {
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 0 !important;
    }

    .MuiTab-root {
        font-size: 15px !important;
    }
}

.tab-divider {
    border: 1px solid #f5f5f5;
    margin-top: -2px;
    margin-bottom: 0;
}
