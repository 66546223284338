.candidate-edit-preview-container {
    display: flex;
    justify-content: space-between;

    .preview-candidate-container {
        width: calc(100vw - 400px);
        padding: 15px;
        box-sizing: border-box;

        .edu-exp-sec {
            .edu-exp-item {
                &:hover {
                    .period {
                        display: block !important;
                    }
                }
            }
        }

        .skill-sec {
            .skills-item-wrapper {
                .skill-item {
                    &:hover {
                        .favorite-indicator {
                            display: block !important;
                        }
                    }
                }
            }
        }

        @media (max-width: 900px) {
            width: 100vw;
        }
    }

    .candidate-preview-notes-container {
        background: $colorFFFFFF;
        border-left: 1px solid $colorE5E5E5;
        height: calc(100vh - 72px);
        position: sticky;
        top: 72px;
        width: 400px;
        padding: 0 20px;
        box-sizing: border-box;

        .loading-wrapper {
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
        }

        @media (max-width: 900px) {
            display: none;
        }
    }

    .mobile-note-btn {
        position: fixed;
        bottom: 30px;
        right: 30px;
    }
}

@media (max-width: 900px) {
    .notes-drawer-container > div {
        max-width: 400px;
        width: -webkit-fill-available;
        padding: 0 20px;
    }
}

.note-block {
    .note-item {
        margin-top: 25px;

        .note-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .note-actions {
                display: flex;
                
                svg {
                    margin-left: 5px;
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                }
            }
        }

        .note-content {
            display: flex;
            justify-content: space-between;
            white-space: pre-line;
            overflow-wrap: break-word;
            color: $color111111;
            font-size: 13px;
            margin-left: 54px;

            .body p:last-child {
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
    }

    .note-block-area {
        overflow: auto;
        height: calc(100vh - 325px);

        @media (max-width: 900px) {
            height: calc(100vh - 255px);
        }
    }
}
