.hide-these-fields {
    position: absolute;
    top: 5px;
    right: 0;
    color: $color344054;
    cursor: pointer;

    svg {
        width: 14px;
        height: 14px;
    }
}