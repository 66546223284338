.filters__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;

    .MuiTypography-body1 {
        font-size: 13px;
        margin-left: 10px;

    }

    .MuiFormControlLabel-root {
        width: 100%;
        padding: 7px;
        margin-left: 0;
        margin-right: 0;

        &:hover {
            background-color: #fff;
            border-radius: 4px;
        }
    }
}

.filters__count {
    color: #aaa;
}

.pagination {
    list-style: none;
    padding-left: 0;

    & > li {
        display: inline-block;
    }
    
    &__link {
        font-size: 14px;
        padding: 10px;
        display: block;
        text-decoration: none;
        color: #aaa;

        &--current {
            color: #222;
        }
    }
}