.my-profile-page {
    max-width: 480px;
    box-sizing: border-box;
    padding-bottom: 40px;

    .page-name {
        margin-top: 40px;
        margin-bottom: 0;
    }

    .sec-heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: $color101828;
        margin-top: 24px;
        margin-bottom: 4px;
    }

    .sec-desc {
        font-size: 14px;
        line-height: 20px;
        color: $color667085;
        margin-top: 0;
    }

    .input-field-with-icon-label-wrapper,
    .phone-wrapper {
        margin-top: 20px;
    }

    .action-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 40px;
    }
}

.special-candidate-link-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    textarea {
        width: 100%;
        padding: 10px 15px;
        height: 44px;
        border-radius: 8px;
        resize: none;
        cursor: text;
        margin-right: 5px;
        border: 1px solid $colorD0D5DD;
        box-sizing: border-box;

        &:focus {
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $colorF4EBFF;
            outline: 0 !important;
        }
    }

    button {
        min-width: 100px;
        box-sizing: border-box;
    }
}

.copied-msg {
    font-size: 13px;
    color: $color7F56D9;
    margin-top: 5px;
}
