body {
    margin: 0;
    padding: 0;
    background-color: $colorF5F5F5;   
}

* {
    // text-transform: none !important;
    font-family: "Inter",sans-serif!important;
    scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    box-shadow: none;
    border-radius: 8px;
    background-color: $colorD7D7DD;
}

.horizontal-scroll-table::-webkit-scrollbar{
    height: 8px;
}
