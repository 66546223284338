.search-page-header-mobile {
    display: none;
}

@media (max-width: 959px) {
   .search-page-header-mobile {
        display: block;
    } 
}

.search-page-container {
    .search-sticky-bars {
        position: sticky;
        padding: 0px 15px 7px 15px;
        background: #f5f5f5;
        z-index: 1;
        box-sizing: border-box;
        top: 65px;
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    .sticky-bars {
        position: sticky;
        padding: 43px 0 10px 15px;
        background: #f5f5f5;
        z-index: 1;
        box-sizing: border-box;
        top: 65px;
        display: flex;
        justify-content: flex-end;
        align-items: end;
    }

    .search-filter-col {
       .sticky-bars {
            position: sticky;
            padding: 28px 0 10px 15px;
            background: #f5f5f5;
            z-index: 1;
            box-sizing: border-box;
            top: 65px;
            display: flex;
            justify-content: flex-end;
            align-items: start;
        } 
    }

    ::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        box-shadow: none;
        background-color: transparent;
    }
}

.search-filter-col {
    width: 240px;
    box-sizing: border-box;

    .page-header {
        //height: 60px;
        margin-bottom: unset;
        align-items: end;
        padding-bottom: 10px;
    }
}

.search-result-col {
    width: calc((100% - 210px)/2)
}

.search-profile-preview-col {
    width: calc((100% - 270px)/2) !important;

    .search-card__action-wrp {
        .recruit-action-btns{
            display: none;
        }
    }
}

@media (max-width: 959px) {
   .search-filter-col {
       width: 100%;

       .filter-bar {
           padding: 0 15px
       }

        .sticky-bars {
                display: none;
        }
    }
}

@media (min-width: 768px) and (max-width: 959px) {
   .search-result-col {
        width: calc((100% + 15px)/2) !important;

        .sticky-bars, .search-wrapper {
            padding-left: 0
        }
    }

    .search-profile-preview-col {
      width: calc((100% - 15px)/2) !important;

        .sticky-bars, .search-wrapper {
            padding-right: 0
        }
    } 

    .search-page-container {
        .search-wrapper {
            height: calc(100vh - 235px);
        }

        .view-project-sec {
            height: calc(100vh - 332px);
        }
    }
}

@media  (max-width: 767px) {
   .search-result-col {
        width: 100%;

        .sticky-bars, .search-wrapper {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .search-profile-preview-col {
        display: none;
    } 
}

.save-as-project-label-btn {
    display: none !important;
}

@media(max-width: 959px) {
    .save-as-project-label-btn {
        display: flex !important;
    }
}

@media(max-width: 575px) {
    .hidden-label-in-mobile {
        display: none;
    }
}

.search-sticky-bars {
    position: sticky;
    padding: 0px 15px 0 15px;
    background: #f5f5f5;
    z-index: 1;
    box-sizing: border-box;
    top: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
} 

@media (min-width: 576px) and (max-width: 959px) {
    .search-page-container {
        .search-sticky-bars {
            top: 140px;
            height: 55px;
        }

        .sticky-bars {
            //height: 55px;
          padding-top: 10px;
        }
    }
}

@media (max-width: 600px) {
    .search-page-container {
        .search-sticky-bars {
            top: 121px;
             height: 65px;
        }
    }
}

@media (max-width: 768px) {
    .search-sticky-bars {
        padding-right: 0;
    }
}

@media (max-width: 959px) {
    .search-result-col {
        .search-sticky-bars {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.search-pagination-div {
    height: 40px;
    padding: 10px 0;
    box-sizing: border-box;
    text-align: right;
    font-size: 14px;
    color: #838383;
}