.select-project-type-modal {
    .modal-action-wrapper {
        justify-content: space-between;

        button {
            width: unset;

            &.save-btn {
                width: 195px;
            }
        }

        a {
            white-space: nowrap !important;
        }
    }

    .project-type-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;

        .project-type-wrapper-item {
            position: relative;
            border: 1px solid $colorEAECF0;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 12px;
            padding: 20px;
            width: 50%;
            cursor: pointer;
            margin: 1px;

            .selected-type-img {
                position: absolute;
                top: 16px;
                right: 16px;

                svg {
                    color: $colorFFFFFF;
                }
            }

            .type {
                font-size: 24px;
                line-height: 32px;
                font-weight: 600;
                color: $color101828;
                margin: 0;
            }

            .remaining-credits {
                font-size: 14px;
                line-height: 20px;
                color: $color475467;
                margin: 8px 0 20px 0;
            }

            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 0;

                li {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 24px;
                    color: $color475467;
                    gap: 14px;
                    margin-bottom: 12px;

                    img {
                        width: 20px;
                        height: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.active {
                border: 2px solid $color7F56D9;
                margin: 0;
            }

            @media (max-width: 575px) {
                width: 90%
            }
        }

        @media (max-width: 575px) {
            flex-direction: column;
        }
    }
}
