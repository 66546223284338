.divider-with-or-wrapper {
    position: relative;
    margin-top: 33px;
    margin-bottom: 33px;

    span {
        position: absolute;
        font-size: 14px;
        line-height: 20px;
        color: $color667085;
        background: $colorFFFFFF;
        left: 44%;
        top: -19px;
        padding: 10px;
    }
}
