.cus-error {
    font-size: 12px;
    color: $colorc10000;
    margin-top: 5px; 
}

.cus-error-border {
    border: 1px solid $colorc10000 !important;
}

.cus-warning {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    line-height: 17px;
    color: $colorF2994A;
    align-items: flex-start;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 0;

    svg {
        width: 16px;
        height: 16px;
    }
}