.company-credits-page {
    .company-credits-active-credits-wrapper.table-list-and-footer-wrapper {
        max-width: 480px;
    }

    #credit-log-list-table {
        height: -webkit-fill-available;
    }

    .table-list-and-footer-wrapper {
        .list-sticky-table-container {
            max-height: calc(100vh - 475px) !important;
        }

        #company-active-credits-list-table-container {
            max-height: 175px !important;
        }
    }
}
