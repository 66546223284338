.image-title-sub-title-wrapper {
    .label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-bottom: 8px;
    }

    .image-title-sub-title-inner-wrapper {
        display: flex;
        align-items: center;
        margin-left: 12px;

        img {
            width: 40px;
            height: 40px;
            margin-right: 12px;
            border-radius: 50%;
            object-fit: contain;
            background: $colorF9F5FF;
        }

        .name-avatar {
            width: 41px;
            height: 41px;
            background: $colorF9F5FF;
            color: $color475467;
            font-weight: 500;
            margin-right: 12px;
            font-size: 14px;
        }

        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $color101828;
            margin: 0;
            white-space: pre-wrap;
        }

        .sub-title {
            font-size: 14px;
            line-height: 20px;
            color: $color667085;
            margin: 0;
            overflow-wrap: anywhere;
        }
    }
}
