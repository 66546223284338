.custom-stepper-wrapper {
    margin-top: 40px;
    margin-bottom: 60px;

    .MuiStep-root {
        .Mui-active {
            color: $color6941C6;
        }

        .Mui-completed {
            color: $color344054;
        }
    }
}
