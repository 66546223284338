.detail-project-page-container {
    .fixed-filter-inner {
        max-height: calc(100vh - 222px);
    }

    .filter-bar {
        height: calc(100vh - 218px);
    }

    .search-wrapper {
        height: calc(100vh - 285px);
    }

    .view-project-sec {
        height: calc(100vh - 418px);
    }

    .sticky-bars {
        top: 183px;
    }

    .result-col-container {
        width: 40%;
        z-index: 2;

        .search-card__head {
            height: auto;
        }

        .search-card__person {
            padding: 20px 0 20px 20px;
        }

        .sticky-bars {
            padding: 25px 15px 8px 0;
            height: 70px;
        }

        .search-wrapper {
            height: calc(100vh - 313px);
            padding-left: 0;
            margin-top: 10px;
        }
    }

    .preview-col-container {
        width: 60%;
        padding: 25px 0 15px 5px;
        box-sizing: border-box;

        .view-project-sec {
            height: calc(100vh - 389px);
        }
    }

    ::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        box-shadow: none;
        background-color: transparent;
    }
}

.search-wrapper {
    .search-card__more-btn {
        position: absolute;
        top: 23px;
        right: 10px;
    }
}

@media (max-width: 575px) {
    .search-wrapper {
        .search-card__more-btn {
            right: 5px;
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .detail-project-page-container {
        .result-col-container {
            width: 45%;
        }

        .preview-col-container {
            width: 55%;
        }
    }
}

@media (min-width: 768px) and (max-width: 959px) {
    .detail-project-page-container {
        .search-wrapper {
            height: calc(100vh - 260px);
        }

        .view-project-sec {
            height: calc(100vh - 398px);
        }
    }
}

@media (max-width: 767px) {
    .detail-project-page-container {
        .result-col-container {
            width: 100%;

            .sticky-bars,
            .search-wrapper {
                padding-right: 0;
            }
        }

        .preview-col-container {
            display: none;
        }
    }

    .search-wrapper {
        height: unset !important;
    }
}

@media (max-width: 575px) {
    .detail-project-page-container {
        .result-col-container {
            .sticky-bars {
                top: 169px;
                padding: 15px 0 15px 0;
                height: 58px;
            }
        }
    }
}

.copy-to-another-project-btn {
    min-width: 35px !important;
    padding: 5px 6px !important;
    height: 40px;
    width: 40px;

    .MuiButton-startIcon {
        margin: 0 !important;
        align-items: center;
    }

    &:hover {
        background: #e5e5e5 !important;
    }
}

.non-mobile-copy-to-another-project-name {
    display: none;
}

@media (max-width: 530px) {
    .suggested-page-bulk-action-btn {
        min-width: 35px !important;
        padding: 5px 6px !important;
        margin-left: 10px !important;
        height: 40px;
        width: 40px;

        .MuiButton-endIcon {
            margin: 0 !important;

            align-items: center;
        }
    }

    .non-mobile-bulk-action-btn-suggested {
        display: none;
    }
}

@media (min-width: 768px) {
    .suggested-page-bulk-action-btn {
        min-width: 35px !important;
        padding: 5px 6px !important;
        margin-left: 10px !important;
        height: 40px;
        width: 40px;

        .MuiButton-endIcon {
            margin: 0 !important;
            align-items: center;
        }
    }

    .non-mobile-bulk-action-btn-suggested {
        display: none;
    }
}

.preloading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
