.sign-up-container {
    display: flex;
    box-sizing: border-box;

    .upper-title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
        color: #646464;
        }

    .content-wrapper {
        width: 45%;
        // height: 100vh;
        background: $colorFFFFFF;
        padding: 32px;
        box-sizing: border-box;
        overflow-y: auto;

        .form-content-wrapper {
            max-width: 421px;
            margin: auto;
            margin-top: 40px;

            .heading {
                font-family: 'Lufga' !important;
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                margin: 0px;
            }

            .sub-heading {
                line-height: 24px;
                color: $color667085;
                margin-top: 12px;
                margin-bottom: 32px;
            }

            .input-field-with-icon-label-wrapper,
            .phone-wrapper {
                margin-top: 20px;
            }

            button {
                width: 100%;
            }
        }
    }
    

    .details-wrapper {
        position: relative;
        width: 55%;
        height: 125vh;
        background: $colorFFFCF0;
        padding: 96px 64px 0 80px;
        box-sizing: border-box;
        overflow: hidden;

        .title-component{
            display: flex;
            gap: 20px;
            align-items: center;

            .title-names{
                display: inline-block;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                height: fit-content;
                vertical-align: middle;

                span{
                    font-weight: 500;
                }
            }
        }

        img{
            max-height: 60px;
        }

        .image-container {
            margin-top: 40px;
            position: absolute;
            height: 100vh;
            width: -webkit-fill-available;
            background: url("../../assets/images/testimonial_image.svg");
            background-repeat: no-repeat;
            background-size: 123vh;
            // border-top: 5px solid;
            // border-left: 5px solid;
            // box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
            // border-top-left-radius: 12px;
        }

        @media (min-width: 993px) and (max-width: 1440px) {
            padding: 96px 35px 0 45px;

            .testimonial-wrapper {
                .testimonial-desc {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .fb-container{
        display: grid !important;
        grid-template-columns: 1fr;

        .content-wrapper{
            height: fit-content;
            min-height: fit-content;
            padding-bottom:60px;

            .site-logo{
                margin-left: auto;
                margin-right: auto;
                display: block;
                height: 31px;
            }

            .form-content-wrapper{
                .heading{
                    display: block;
                    font-size: 32px;
                    text-align: center;
                }

                .sub-heading{
                    color: #000000;
                    font-size: 14px;
                    text-align: center;
                }

                .agreed-to-terms-of-service{
                    color: #000000;

                    a{
                        color: #3C50E0;
                    }
                }
            }
        }

        .details-wrapper{
            width: 100%;
            box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
            margin-top: 0;
            padding: 0px;
            padding-top: 50px;

            .image-container{
                //left: 27%;
                margin-top: 2%;
                position: absolute;
                height: 66vh;
                background-repeat: no-repeat;
                background-size: 106%;
            }

            .title-component, .upper-title-holder{
                max-width: 421px;
                margin-left: auto;
                margin-right: auto;
                padding-left: 32px;
            }

            .title-component{
                img{
                   height: 36px;
                }

                .title-names{
                    font-size: 14px;
                    line-height: 14px;

                    span{
                      font-size: 10px;
                    }
                }
            }

            .upper-title{
                padding-right: 30px;
                font-size: 10px;
                line-height: 12px;
                text-transform: capitalize;
                font-family: 'Inter';
                font-style: normal;
                width: 279px;
            }
        }
    }

    .content-wrapper {
        width: 100%;
        height: unset;
        min-height: 100vh;
        overflow-y: unset;
    }

    .sign-up-container .content-wrapper {
        width: 100%;
    }
}