.big-header {
    text-align: center;
}
.small-header {
    text-align: center;
}
.login-btn-container {
    margin-top: 53px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
}
.makeStyles-root-1{
    margin-top: 24px;
    margin-bottom: 24px;
}
.jss1{
    margin-top: 24px;
    margin-bottom: 24px;
}
.traditional-login-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px !important;
}
.traditional-login-text-area{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    align-items: center;
    border: 0;
    padding: 16px 24px;
    padding-left: 0 !important;
    font-size: 12px;
    padding-right: 0 !important;
    outline: none;
    transition: all .2s ease-in-out;
}
.pre-traditional-login-line{
    min-width: 10px;
    height: 0px;
    border: 1px solid #888888;
    margin-right: 10px !important;
    text-align: left;
}
.post-traditional-login-line{
    min-width: 10px;
    height: 0px;
    border: 1px solid #888888;
    margin-left: 10px !important;
    text-align: right;
}
.traditional-login-line-text {
    min-width: fit-content;
    height: 15px;
    font-size: 11px;
    line-height: 14px;
    color: #111111;
    text-align: center;
}
.login-option-btn {
    cursor: pointer;
    position: absolute;
    align-items: center;
    background: #FFFFFF;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(22, 19, 19, 0.25);
    padding: 16px 24px;
    font-size: 16px;
    outline: none;
    transition: all .2s ease-in-out;
    color: #111111 !important;
}
.login-btn-grp{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px !important;
}
.button-grp-continer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-option-btn:hover, 
.login-option-btn:focus {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}
.login-option-btn:active {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.login-option-btn[disabled], 
.login-option-btn.inactive {
    opacity: 0.4;
    box-shadow: none;
}
.btn-ico {
    margin-right: 16px;
    vertical-align: middle;
}
.info-ico {
    position: absolute;
    top: -7px;
    right: -7px;
}

.log-error-massage{
    text-align: center;
    color: red;
}

.auth-header {
    font-style: normal;
    font-weight: 400 !important;
    text-align: center;
}