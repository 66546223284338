.profile-fluid-container {
    background: $colorF5F5F5;
    min-height: auto;

    .profile-page-header {
        display: flex;
        height: 32px;
        align-items: center;
        background: $colorF5F5F5;
        z-index: 3;
    }

    @media (max-width: 575px) {
        .custom-container {
            width: calc(100% - 40px);
        }
    }
}

@media (max-width: 600px) {
    .profile-fluid-container {
        margin-top: 10px;
    }
}

.profile-container {
    display: flex;
    color: $color000000;
    min-width: -webkit-fill-available;

    .profile-side-nav-bar {
        width: 250px;

        .fixed-bar {
            position: fixed;
            width: inherit;
            padding-right: 20px;
            box-sizing: border-box;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    font-size: 15px;
                    line-height: 18px;
                    color: $color000000;
                    margin-bottom: 10px;
                    transition-timing-function: ease-in;
                    transition-duration: 80ms;
                    cursor: pointer;

                    &:hover {
                        font-weight: 600;
                    }
                }

                li.active {
                    font-weight: 600;
                }
            }
        }
    }

    .profile-content-container {
        width: calc(100% - 250px);

        .common-wrapper {
            position: relative;
            background: $colorFFFFFF;
            border: 1px solid $colorE5E5E5;
            border-radius: 8px;
            margin-top: 3px;
            margin-bottom: 32px;

            .insights-sec {
                .insights {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .insight-item {
                        .count {
                            font-weight: 600;
                            font-size: 32px;
                            line-height: 39px;
                            margin: 0;
                        }

                        .title {
                            font-size: 15px;
                            line-height: 18px;
                            margin: 0;
                        }
                    }

                    .insight-divider {
                        width: 5px;
                        height: 48px;
                        border-right: 1px solid $colorD7D7DD;
                    }
                }

                @media (max-width: 950px) {
                    .insights {
                        display: block;

                        .insight-item {
                            display: inline-block;
                            width: 50%;
                            vertical-align: text-top;
                        }

                        .insight-item:nth-child(1) {
                            margin-bottom: 25px;
                        }

                        .insight-divider {
                            display: none;
                        }
                    }
                }
            }

            .about-sec {
                .contact-wrapper {
                    display: flex;

                    .profile-img {
                        width: 80px;
                        height: 80px;
                        position: relative;

                        img {
                            width: 80px;
                            height: 80px;
                            object-fit: cover;
                            border-radius: 50%;

                            @media (max-width: 480px) {
                                width: 60px;
                                height: 60px;
                            }
                        }

                        .status-indicator {
                            position: absolute;
                            bottom: 0;
                            right: 3%;

                            @media (max-width: 480px) {
                                bottom: 0;
                                right: -5%;
                            }
                        }

                        .profile-pic {
                            width: 80px;
                            height: 80px;
                            font-size: 1.5rem;

                            @media (max-width: 480px) {
                                width: 60px;
                                height: 60px;
                            }
                        }

                        @media (max-width: 480px) {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    .contact-info {
                        padding-left: 16px;
                        overflow-wrap: anywhere;

                        .name {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 29px;
                            margin: 0;
                        }

                        .location {
                            font-size: 15px;
                            line-height: 18px;
                            margin: 2px 0 6px 0;
                        }

                        .contact-ways {
                            display: flex;
                            align-items: center;
                            margin: 0;
                            flex-flow: wrap;
                            line-height: 28px;

                            .contact-item {
                                display: inline-flex;
                                align-items: center;
                                margin-right: 24px;
                                font-size: 15px;

                                svg {
                                    margin-right: 6px;
                                    width: 16px;
                                    height: 16px;
                                }
                            }

                            .divider {
                                margin-right: 24px;
                                color: $colorD7D7DD;

                                @media (max-width: 480px) {
                                    display: none;
                                }
                            }

                            .social-links {
                                display: flex;
                                color: $color000000;
                                margin-right: 24px;
                                margin-bottom: 5px;

                                svg {
                                    width: 16px;
                                    height: 16px;

                                    path {
                                        stroke: unset;
                                    }
                                }

                                .non-colored-icons {
                                    svg {
                                        path {
                                            stroke: currentColor;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .summery {
                    font-size: 15px;
                    line-height: 140%;
                    margin-top: 24px;
                    margin-bottom: 0;
                    white-space: pre-line;
                }

                &:hover {
                    .cus-edit-btn {
                        display: flex;
                        position: absolute;
                        top: 16px;
                        right: 18px;
                    }
                }

                .status-wrapper {
                    margin-top: 20px;

                    .label {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: $color344054;
                        margin-bottom: 8px;

                        svg {
                            cursor: pointer;
                        }
                    }

                    .status-btn-wrapper {
                        display: flex;
                        gap: 16px;

                        .status-btn {
                            background: $colorFFFFFF;
                            border: 1px solid $colorAAAAAA;
                            border-radius: 4px;
                            padding: 24px;
                            cursor: pointer;

                            .title-div {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .status {
                                    font-weight: 600;
                                    font-size: 15px;
                                    line-height: 18px;
                                    color: $color111111;
                                }
                            }

                            .info {
                                font-size: 13px;
                                line-height: 16px;
                                color: $color000000;
                                margin-top: 10px;
                                margin-bottom: 0;
                            }
                        }

                        .status-btn.active {
                            background: $colorF4EBFF;
                        }

                        @media (max-width: 375px) {
                            flex-direction: column;
                        }
                    }

                    @media (max-width: 575px) {
                        width: 100%;
                    }
                }
            }

            .next-role-sec {
                .role-item-wrapper {
                    display: inline-flex;
                    flex-wrap: wrap;
                    gap: 32px;
                    box-sizing: border-box;
                    justify-content: space-between;
                    width: 100%;

                    .role-item {
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        width: 46%;
                        gap: 16px;

                        .role-icon {
                            width: 32px;

                            svg {
                                width: 32px;
                                height: 32px;
                            }
                        }

                        .role-content {
                            padding-right: 20px;

                            p {
                                font-size: 15px;
                                line-height: 18px;
                                margin: 0;
                                margin-bottom: 5px;

                                &:first-child {
                                    font-weight: 600;
                                    font-size: 15px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }

                    @media (max-width: 575px) {
                        .role-item {
                            width: 100%;
                        }
                    }
                }

                @media (max-width: 900px) {
                    .role-item {
                        display: flex;
                        width: 100%;

                        .role-content {
                            padding-right: 0;
                        }
                    }
                }

                &:hover {
                    .cus-edit-btn {
                        display: flex;
                        position: absolute;
                        top: 16px;
                        right: 18px;
                    }
                }

                .notice-period-wrapper {
                    .monthly-dropdown {
                        border: 0;
                        min-width: 80px;
                        margin-left: 5px;
                        outline: 0;
                        border-bottom: 1px solid $color111111;
                        background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
                            transparent no-repeat calc(100% - -5px) !important;
                    }

                    .specific-date {
                        margin-left: 5px !important;
                        border-bottom: 1px solid $color111111;

                        .MuiInputBase-input {
                            padding: 0 !important;
                            width: 116px;
                            font-size: 15px;
                        }

                        .MuiInput-root {
                            height: auto !important;
                        }
                    }
                }
            }

            .edu-exp-sec {
                .edu-exp-item {
                    position: relative;
                    margin-top: 32px;

                    .edu-exp-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        min-height: 35px;

                        .institute-info {
                            .title {
                                font-weight: 700;
                                font-size: 15px;
                                line-height: 18px;
                                margin: 0;
                            }

                            .sub-title {
                                font-size: 15px;
                                line-height: 22px;
                                margin: 0;
                            }
                        }

                        .period-info {
                            .period {
                                font-size: 15px;
                                line-height: 22px;
                                text-align: right;
                                color: $colorAAAAAA;
                                margin: 0;
                            }
                        }
                    }

                    .desc {
                        font-size: 15px;
                        line-height: 150%;
                        margin: 0;
                        margin-top: 12px;
                        white-space: pre-line;
                    }

                    &:hover {
                        .cus-edit-btn {
                            display: flex;
                            position: absolute;
                            right: 0;
                        }

                        .period {
                            display: none;
                        }
                    }
                }
            }

            .skill-sec {
                .favorite-skills-wrapper {
                    margin-top: 58px;
                    margin-bottom: 56px;

                    p {
                        font-size: 15px;
                        line-height: 20px;
                        text-align: center;
                        color: $colorAAAAAA;
                        margin: 0;
                    }

                    p:nth-child(1) {
                        font-weight: 700;
                    }
                }

                .divider {
                    margin-top: 32px;
                    margin-bottom: 32px;
                    border-top: 1px solid $colorDDDDDD;
                }

                .skills-item-wrapper {
                    display: inline-flex;
                    flex-wrap: wrap;
                    gap: 16px;

                    .skill-item {
                        position: relative;
                        background: $colorE9E9F1;
                        font-size: 15px;
                        line-height: 18px;
                        border-radius: 8px;
                        padding: 16px 28px;
                        width: fit-content;
                        box-sizing: border-box;
                        cursor: pointer;

                        .favorite-indicator {
                            position: absolute;
                            top: -10px;
                            right: -7px;
                            color: $colorFFFFFF;
                            border-radius: 50%;
                            padding: 4px;
                            background: $color7F56D9;
                            width: 16px;
                            height: 16px;
                            fill: currentColor;
                            cursor: pointer;

                        }

                        .favorite-btn {
                            display: none;
                            position: absolute;
                            top: -10px;
                            right: 21px;
                            color: $colorFFFFFF;
                            border-radius: 50%;
                            padding: 4px;
                            background: $color7F56D9;
                            width: 16px;
                            height: 16px;
                            cursor: pointer;

                            path {
                                fill: currentColor;
                            }
                        }

                        .delete-btn {
                            display: none;
                            position: absolute;
                            top: -10px;
                            right: -7px;
                            color: $colorFFFFFF;
                            border-radius: 50%;
                            padding: 4px;
                            background: $color7F56D9;
                            width: 16px;
                            height: 16px;
                            cursor: pointer;
                        }

                        &:hover {
                            .favorite-indicator {
                                display: none;
                            }

                            .favorite-btn,
                            .delete-btn {
                                display: block;
                            }
                        }
                    }
                }
            }

            .languages-sec {
                .languages-item-wrapper {
                    display: inline-flex;
                    flex-wrap: wrap;
                    gap: 16px;

                    .languages-item {
                        position: relative;
                        font-size: 15px;
                        line-height: 18px;
                        background: $colorE9E9F1;
                        border-radius: 8px;
                        padding: 16px 28px;
                        width: fit-content;
                        box-sizing: border-box;
                        cursor: pointer;

                        p {
                            margin: 0;
                        }

                        p:nth-child(2) {
                            font-size: 14px;
                            color: $color9B9B9B;
                        }

                        .delete-btn {
                            display: none;
                            position: absolute;
                            top: -10px;
                            right: -7px;
                            color: $colorFFFFFF;
                            border-radius: 50%;
                            padding: 4px;
                            background: $color7F56D9;
                            width: 16px;
                            height: 16px;
                            cursor: pointer;
                        }

                        &:hover {
                            .delete-btn {
                                display: block;
                            }
                        }
                    }
                }
            }

            .modal-action-container {
                display: flex;
                margin-top: 32px;
                gap: 10px;

                button {
                    width: -webkit-fill-available;
                }
            }
        }

        .inner-container {
            max-width: 400px;
        }
    }

    .input-field-with-icon-label-wrapper,
    .phone-wrapper,
    .async-autocomplete-wrapper {
        margin-top: 25px;
    }

    @media (max-width: 768px) {
        .profile-side-nav-bar {
            display: none;
        }

        .profile-content-container {
            width: 100%;
        }
    }

    @media (min-width: 769px) and (max-width: 992px) {
        .profile-side-nav-bar {
            width: 180px;
        }

        .profile-content-container {
            width: calc(100% - 180px);
        }
    }
}

.profile-status {
    font-size: 16px;
    border: 2px solid $colorFFFFFF;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}

.profile-status-indicator {
    border: 2px solid $colorFFFFFF;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}

.profile-active-status {
    color: $color12B76A;
}

.profile-pending-status {
    color: $colorAAAAAA;
}

.profile-pending-activation-status {
    color: $color171291;
}

.profile-semi-active-status {
    color: $colorF79009;
}

.profile-inactive-status {
    color: $colorF04438;
}

.sec-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;
    height: 42px;

    .title-sec {
        display: flex;
        align-items: center;

        .title {
            display: inline-block;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: $color000000;
            margin: 0;
            margin-right: 10px;
        }

        .count {
            display: inline-block;
            font-size: 15px;
            line-height: 18px;
            color: $color7F56D9;
            background: rgba(118, 30, 228, 0.08);
            border-radius: 99px;
            padding: 8px 20px;
        }
    }
}

.cus-padding {
    padding: 48px;
}

@media (max-width: 575px) {
    .cus-padding {
        padding: 20px;
    }
}

.cus-dropdown-filter-wrapper {
    padding: 12px 20px;
    border-radius: 4px;
    transition-timing-function: ease-in-out !important;
    transition-duration: 80ms !important;
    cursor: pointer;

    .cus-dropdown-filter {
        appearance: auto;
        background: none !important;
        border: 0;
        font-size: 15px;
        line-height: 18px;
        text-align-last: right;
        border-radius: 4px;
        outline: none;

        option {
            direction: ltr;
        }
    }

    &:hover {
        background: rgba(118, 30, 228, 0.08) !important;
    }
}

.cus-edit-btn {
    display: none;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: $color7F56D9;
    background: $colorFFFFFF;
    padding: 12px 20px;
    border: 0;
    border-radius: 4px;

    svg {
        margin-right: 6px;
        width: 18px;
        height: 18px;
    }

    &:hover {
        cursor: pointer;
        background: rgba(118, 30, 228, 0.08);
    }
}

.show-more-btn {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: $color7F56D9;
    background: transparent;
    padding: 12px 20px;
    border: 0;
    border-radius: 4px;
    margin: auto;
    margin-top: 40px;

    svg {
        margin-right: 6px;
        font-size: 18px;
    }

    &:hover {
        cursor: pointer;
        background: rgba(118, 30, 228, 0.08);
    }
}

.cus-add-btn {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: $color7F56D9;
    background: transparent;
    padding: 12px 20px;
    border: 0;
    border-radius: 4px;

    svg {
        margin-right: 6px;
        width: 18px;
        height: 18px;
    }

    &:hover {
        background: rgba(118, 30, 228, 0.08);
        cursor: pointer;
    }
}

.profile-pic-upload {
    .upload-paper {
        position: relative;
        width: fit-content;
        border: 0;
    }

    .label {
        display: block;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: $color111111;
        margin-bottom: 8px;
    }

    .photo-preview {
        position: relative;
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        .close-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            background: $color7F56D9;
            color: $colorFFFFFF;
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .upload-box__inner {
        min-height: unset;
        padding: 16px;
        width: 400px;
        border: 2px dashed $colorE5E5E5;
        box-sizing: border-box;
        cursor: pointer;

        .upload-box__text-lg {
            font-size: 15px;
        }
    }
}

.not-verified-warning {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    line-height: 17px;
    color: $colorF2994A;
    margin: 0;
    align-items: flex-start;
    gap: 5px;
    margin-top: 3px;
    max-width: 400px;

    svg {
        width: 16px;
        height: 16px;
    }
}

.pre-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;

    svg {
        color: $color7F56D9;
    }
}
