.notes-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .note-items {
        margin-top: 25px;
        margin-bottom: 15px;

        .noter-sec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-content: center;

            .avatar {
                width: 24px;
                height: 24px;
                font-size: 10px;
                margin-right: 8px;
                display: inline-flex;
            }


            .name {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 13px;
                line-height: 150%;
                margin-right: 8px;
            }

            .comment-tool-tip {
                display: flex;
            }

            .time {
                font-size: 13px;
                line-height: 150%;
                color: #aaaaaa;
            }

            .icon-comp  {
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .body {
            margin-top: 10px;

            p {
                font-size: 13px;
                line-height: 150%;
                color: #111111;
                white-space: pre-line;
                overflow-wrap: break-word;
                margin: 0;
            }
        }

        .desc {
            font-size: 13px;
            line-height: 150%;
            color: #111111;
            margin-top: 10px;
            white-space: pre-line;
            overflow-wrap: break-word;
        }
    }

    .note-type-and-send-wrapper {
        margin-top: auto;
        padding-bottom: 10px;
        position: absolute;
        bottom: 0;
        width: calc(100% - 40px);
        background: #fff
    }

    .note-type-and-send {
        display: flex;
        gap: 8px;
        width: 100%;
        box-sizing: border-box;
        bottom: 0;
        background: #fff;

        textarea {
            position: relative;
            width: inherit;
            resize: none;
            padding: 5px 10px 5px 10px;
            box-sizing: border-box;
            background: #f5f5f5;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            line-height: 20px;
            min-height: 35px;
            max-height: 125px;

            &:focus {
                outline: none;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $colorF4EBFF;
            }
        }

        button {
            background-color: #f5f5f5;
            width: 40px;
            height: 35px;
        }

        .comment-icon {
            top: 16px;
            left: 13px;
            color: #111111;
            z-index: 1;
        }

        .send-icon {
            background: $color7F56D9;
            cursor: pointer;

            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    .note-share-btn {
        padding-top: 16px;

        label {
            margin-left: 0;
        }

        span {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
        }

        .MuiFormControlLabel-label {
            padding-left: 8px;
        }
    }
}

.note-item {
    .image-title-sub-title-wrapper .image-title-sub-title-inner-wrapper .title {
        display: flex;

        .tool-tip-with-title {
            display: flex;
            align-items: center;
        }

        .comment-tool-tip {
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}