.page-name {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: $color000000;
    letter-spacing: -0.02em;
}

.page-result-count {
    font-size: 14px;
    line-height: 20px;
    color: $color667085;
    padding-left: 5px;
}

@media (min-width: 576px) and (max-width: 991px) {
    .page-name {
        font-size: 25px;
        line-height: 28px;
    }
}

@media (min-width: 481px) and (max-width: 575px) {
    .page-name {
        font-size: 20px;
        line-height: 23px;
    }
}

@media (max-width: 480px) {
    .page-name {
        font-size: 16px;
        line-height: 18px;
    }
}
