.search-card {
    border-radius: 6px;
    margin-bottom: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    position: relative;

    &.bulk-selected {
        box-shadow:
            0 0 15px rgba(0, 0, 0, 0.05),
            0 0 0 2px #1c0eb9 !important;
    }

    &.search-card--inactive-show {
        opacity: 0.6 !important;
        pointer-events: none;
    }
}

.search-card__head {
    display: flex;
    justify-content: space-between;
    height: 76px;
}

.search-card__person {
    display: flex;
    align-items: center;
    padding: 24px 0 16px 20px;
}

.search-card__person-info {
    min-width: 100px;
    line-break: anywhere;
    padding-right: 35px;
    margin-left: 10px;
}

.search-card__person-name {
    font-size: 13px;
    line-height: 150%;
    color: #111111 !important;
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-card__person-location {
    font-size: 13px;
    color: #111111;
    text-transform: capitalize;
}

.search-result-up-opt {
    padding: 24px 0 16px 20px !important;
    position: absolute !important;
    right: 16px;
}

.search-card__action-wrp {
    margin-left: auto;
    flex-wrap: nowrap;
    padding: 0 20px 15px 0;
    display: flex;
    justify-content: end;

    .recruit-action-btns {
        text-align: right;
        display: inline-block;

        .request-pending-btn {
            position: relative;
            right: 30px;
            min-width: 45px;

            .MuiButton-startIcon {
                margin: 0 !important;
            }
        }

        .action-btn {
            padding: 5px 16px;
            background: $color7F56D9 !important;
            color: #fff !important;
            position: relative;
            right: 30px;
            min-width: 45px;
            box-shadow: none;
            height: 40px;

            img {
                vertical-align: bottom;
            }

            svg {
                width: 18px;
                height: 18px;
            }

            .MuiButton-startIcon {
                margin: 0 !important;
            }

            .loading {
                color: #ffffff;
            }
        }
    }
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid #e5e5e5;
}

.MuiTabScrollButton-root {
    width: 20px;
}

.filters__item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.filters__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 20px;
    width: 99%;

    .MuiButton-sizeSmall {
        margin: 0;
        padding: 0;
        min-width: fit-content;
        height: 0;

        &:hover {
            color: #111111;
        }
    }
}

.filters__option-btn {
    color: #aaa !important;
}

.filters__title {
    font-weight: 600;
    color: #111111;
    font-size: 14px;
}

.range-slider__details {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;

    .range-slider__to {
        margin: 3px 5px;
        display: flex;
        align-items: center;
    }
}

.page-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.page-header__label {
    font-size: 14px;
    font-weight: 400;
    margin-left: 16px;
    color: #999999;
    margin-top: 13px;
}

.page-header__page-action-btn:not(:first-child) {
    margin-left: 16px;
}

.sort-wrapper {
    padding: 0.8em 1em 0.8em 2em;
    display: flex;
    align-items: center;
    border: 0;
}

.sort-wrapper__label {
    font-size: 13px;
    line-height: 1;
    white-space: nowrap;
}

.upload-box {
    position: relative;
    padding: 40px;
    outline: none !important;
    cursor: pointer;

    &__inline {
        display: inline-block;
    }

    &__input {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 190px;
        justify-content: center;
        border: 2px dashed #1c0eb9;
        border-radius: 4px;
        padding: 8px;
        text-align: center;
    }

    &__ico {
        margin-bottom: 24px;
    }

    &__text-lg {
        font-size: 14px;
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
    }

    &__text-formats {
        font-size: 12px;
        color: #aaa;
        margin-top: 5px;
    }
}

.page-title {
    font-weight: 900 !important;
    margin-bottom: 1rem !important;
}

.page-desc {
    margin-top: 0;
    margin-bottom: 3.75rem;
    color: #aaa;
}

.no-resume {
    margin-top: 1.5rem;
    text-align: center;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.candidate {
    padding: 24px;
    margin-bottom: 24px;
    position: relative;
}

.candidate__footer {
    width: auto !important;
    margin: 24px -24px -24px -24px;
    padding: 10px 24px;
    background-color: #eeeeee;
    border-radius: 0 0 6px 6px;
    font-size: 12px;
}

.candidate__title-wrp {
    flex: 1;
    padding-right: 10px;
}

.candidate__position {
    font-size: 18px;
    font-weight: bold;
}

.candidate__period {
    font-size: 16px;
}

.candidate__company {
    font-size: 14px;
    margin-top: 5px;
}

.candidate--empty {
    text-align: center;
    border: 1px dashed #1c0eb9;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
}

.candidate__add {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #1c0eb9;
    border: 0;
    background: transparent;
    outline: none !important;
    cursor: pointer;
}

.form-group__input::before,
.grit-input .MuiInput-underline::before,
.form-group__input::after,
.grit-input .MuiInput-underline::after {
    display: none;
}

.form-group {
    margin-bottom: 2rem;

    .grit-input {
        background: #fff !important;
    }

    .MuiInput-root {
        background: #fff !important;
    }

    .MuiCheckbox-root {
        margin-left: 12px;
        margin-right: 10px;
    }

    .MuiInputBase-root {
        input {
            padding: 12px 10px !important;
        }
    }
}

.grit-clear-input {
    box-shadow: none;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
    display: none;
}

.MuiAutocomplete-root .MuiFormLabel-root {
    display: none;

    & + .MuiInput-formControl {
        margin-top: 0;
    }
}

.grit-input .MuiInput-root {
    height: 100%;
}

textarea.grit-input {
    resize: vertical;
    min-height: 150px;
    border: 0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 11px 16px;
    font-family: inherit;
    outline: none !important;

    &::placeholder {
        color: #bbb;
    }
}

.form-group .MuiSelect-select {
    height: 36px;
    line-height: 25px;
    background-color: transparent !important;
    box-sizing: border-box;
    padding-left: 10px;
}

.MuiSelect-select:focus {
    background-color: unset !important;
}

.form-group__label {
    margin-bottom: 1rem;
    font-weight: 500;
    display: inline-block;

    sup {
        line-height: 0.5;
    }
}

.form-group__label--block,
.form-group.block .form-group__label {
    display: block;
}

.form-group.block .MuiInputBase-root {
    width: 100%;
    background: #fff !important;
}

.form-group .MuiAutocomplete-inputRoot {
    background: #fff !important;
}

.form-group.block .form-group__input {
    width: 100%;
}

.skills__add-btn {
    margin-left: 8px !important;
}

.profile .form-group .form-group__label {
    font-size: 16px;
}

/****/
.skills__item .form-group__label {
    margin-bottom: 8px;
}

.form-group__help-text {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 15px;
}

.skills__add-skills-wrp {
    margin-top: 15px;
}

.MuiChip-root.grit-chip {
    min-width: 192px;
    border-radius: 8px;
    padding: 14px 4px;
    min-height: 40px;
    justify-content: space-between;
    background-color: #eee !important;
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.MuiChip-root.grit-chip:last-child {
    margin-right: 0;
}

.grit-chip.MuiChip-deletable .MuiChip-label {
    padding-right: 20px;
}

.MuiChip-root.grit-chip.grit-chip--filter {
    padding: 10px 3px;
    font-size: 13px;
    margin: 0 0 8px;
    height: 100%;
    min-width: -moz-fit-content;
    max-width: 100%;
}

.grit-chip.grit-chip--multiline {
    min-height: 64px;
}

.skills__skill-chip {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-size: 16px;
}

.MuiChip-root.grit-chip .MuiChip-label {
    flex-grow: 1;
    padding-right: 15px;
}

.MuiChip-root.grit-chip--filter .MuiChip-label {
    padding: 0 10px;
}

.grit-chip--multiline .skills__skill-chip {
    flex-direction: column;
    align-items: flex-start;
}

.grit-chip--block {
    width: 100%;
}

.skills__fav-btn {
    display: flex;
    border: 0;
    color: #1c0eb9;
    background: transparent;
    cursor: pointer;
    outline: none !important;
}

.skills__fav-btn svg {
    width: 15px;
}

.skills__skill-sub {
    color: #aaa;
    margin-top: 1px;
}

.grit-chip.MuiChip-deletable .MuiChip-deleteIcon {
    color: #999999;
    width: 18px;
    padding-left: 10px;
    vertical-align: middle;
}

.skills {
    margin-top: 40px;
}

.skills:not(.skills--profile) .skills__item + .skills__item {
    margin-top: 80px;
}

/*************/

.MuiSkeleton-root {
    border-radius: 8px;
}

.filter-loading-placeholder {
    transform: scale(1, 0.9) !important;
}

.filter-loading-placeholder__head {
    margin-bottom: 5px;
}

.salary-filter {
    display: flex;
    align-items: center;

    .currency-dropdown {
        margin-bottom: 10px;
        border-radius: 8px;
        background: none;
        padding: 5px 8px;
        margin-right: 5px;
        border: 1px solid #d0d5dd;
        width: 150px;
        height: 44px;
        cursor: pointer;

        &:focus {
            outline: 0;
            box-shadow:
                0px 1px 2px rgba(16, 24, 40, 0.05),
                0px 0px 0px 4px #f4ebff;
        }
    }

    .MuiInputBase-input {
        padding: 12px 10px;
        height: 44px;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 13px;
        border: 1px solid #d0d5dd;

        &:focus {
            box-shadow:
                0px 1px 2px rgba(16, 24, 40, 0.05),
                0px 0px 0px 4px $colorF4EBFF;
        }
    }
}

.range-slider .MuiSlider-rail {
    background-color: #ccc;
}

.range-slider .MuiSlider-thumb {
    background-color: #fff;
    border: 2px solid #7f56d9;
    width: 16px;
    height: 16px;
}

.range-slider .MuiSlider-valueLabel {
    top: -45px;
    left: calc(-50% - 10px);

    & > span {
        background-color: #222;
        width: 45px;
        height: 45px;
    }
}

.filter-chip__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiSwitch-track {
        border-radius: 10px;
    }

    .MuiSwitch-root {
        margin-left: 5px;
        padding: 3px;
        flex-shrink: 0;
    }
}

.filter-selected-item-close-btn {
    svg {
        width: 16px;
        height: 16px;
        color: $color98A2B3;
        margin-right: 5px;
    }
}

.filters__body .MuiAutocomplete-fullWidth .MuiInput-root {
    border: 0;

    .MuiSvgIcon-root {
        display: none !important;
    }
}

.filters__body {
    .MuiInputBase-input {
        font-size: 13px;
        padding: 0 !important;
    }

    .MuiAutocomplete-root {
        .MuiInputBase-root {
            border: 1px solid #d0d5dd;

            &:focus-within {
                box-shadow:
                    0px 1px 2px rgba(16, 24, 40, 0.05),
                    0px 0px 0px 4px #f4ebff;
            }
        }
    }
}

@media (max-width: 575px) {
    .filters__body .MuiInputBase-input {
        font-size: 16px;
    }
}

.experience-edit-button,
.education-edit-button {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #1c0eb9;
    color: #fff;
    font-size: 12px;
    border: 0;
    border-radius: 0 6px;
    padding: 5px 10px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.candidate:hover {
    .experience-edit-button,
    .education-edit-button {
        visibility: visible;
        opacity: 1;
    }
}

.filter-chip__inner .filter-chip__inner-txt {
    white-space: nowrap;
    flex-grow: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    padding-top: 2px;
}

.validation-error {
    font-size: 12px;
    color: #c10000;
    margin-top: 10px;
}

.has-error {
    color: #c10000;
}

.MuiFormControl-root {
    margin: 0 !important;
}

.MuiDialogTitle-root {
    padding: 32px 32px 16px 32px !important;
}

.MuiDialogContent-root {
    padding: 8px 32px 8px 32px !important;
}

.MuiDialog-paperWidthXs {
    max-width: 530px !important;
}

.MuiDialog-paper.MuiPaper-rounded {
    border-radius: 8px;
}

.MuiButton-root {
    text-transform: none !important;
    white-space: nowrap !important;
}

.form-bottom .MuiButton-root + .MuiButton-root {
    margin-left: 1.375rem;
}

.MuiDialogTitle-root .MuiTypography-root {
    font-size: 32px;
    font-weight: 900;
    line-height: 1.2;
}

.form-main-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 21px;
}

.notice-period__lbl--multiline {
    align-items: flex-start !important;
}

.notice-period__inner {
    margin-top: 10px;
    margin-bottom: 15px;
}

.notice-period__inner-lb {
    margin-bottom: 1rem;
    display: block;
    cursor: pointer;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list > li:not(:last-child) {
    margin-bottom: 20px;
}

.filter-btn-wrap {
    position: sticky;
    background-color: whitesmoke;
    text-align: center;
    padding: 15px 0;
    overflow: hidden;
    z-index: 1;
    bottom: 0;
}

@media (max-width: 375px) {
    .filter-btn-wrap {
        position: fixed;
        background-color: white;
        width: calc(100% - 30px);
    }
}

@media (min-width: 375px) and (max-width: 959px) {
    .filter-btn-wrap {
        position: fixed;
        background-color: white;
        width: 100%;
        max-width: 345px;
    }
}

.experience-inner {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
    line-height: 1.7;
    font-size: 16px;
}

.readMoreText {
    color: #3f51b5 !important;
    padding: 0;

    &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
    }

    &:hover {
        text-decoration: underline;
    }
}

@media (max-width: 1500px) {
    .upload-box {
        padding: 18px;
    }
}

@media (max-width: 768px) {
    .inner-wrapper {
        padding-top: 10px;
    }

    .MuiToolbar-root {
        justify-content: space-between;
    }

    .page-header {
        flex-direction: column;
        align-items: baseline;
    }

    .page-header__page-action-btn:not(:first-child) {
        margin-left: 0;
    }
}

@media (max-width: 600px) {
    .search-card__action-wrp {
        white-space: nowrap;
    }

    .search-card__more-btn {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.MuiAutocomplete-listbox > .MuiAutocomplete-option:last-child {
    position: sticky;
    bottom: -8px;
    background-color: #fff;
    z-index: inherit;
}

.form-bottom {
    display: flex;
}

.language-proficiency-form-error {
    color: #c10000 !important;
    margin-bottom: 15px !important;
}

.skills-max-limit-form-error {
    color: #c10000 !important;
    margin-bottom: 15px !important;
}

.gap-between-lbl .form-group__label {
    margin-right: 15px;
}

.MuiAutocomplete-noOptions:empty {
    display: none !important;
}

.no-value .MuiAutocomplete-clearIndicatorDirty {
    visibility: hidden !important;
}

@media (min-width: 960px) {
    .fixed-filter-inner {
        max-height: calc(100vh - 98px);
        position: sticky;
        top: 65px;

        .filters-ai-match {
            height: calc(100vh - 80px);
        }
    }
}

@media (max-width: 959px) {
    .range-slider__input .MuiInputBase-root .MuiInputBase-input {
        font-size: inherit;
    }

    .range-slider__details .range-slider__to {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.notice-period__inner-box {
    .MuiInputBase-root {
        padding: 8px !important;

        input[type="date"]::-webkit-calendar-picker-indicator {
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: -15px;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
        }
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.MuiAutocomplete-option[data-focus="true"] {
    background-color: #f5f5f5 !important;
}

.grit-form-v2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 300px !important;
    min-height: 56px !important;
}

.grit-input-v2 {
    background: #ffffff;
    border: 2px solid #888888;
    box-sizing: border-box;
    border-radius: 4px;
}

.grit-input-v2-focus {
    border: 2px solid #111111 !important;
}

.grit-btn-v2 {
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    position: absolute;
    align-items: center;
    background: #111111 !important;
    border: 0 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #ffffff !important;
}

.grit-form-link-small {
    color: #111111 !important;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
}

.pw-eye {
    cursor: pointer;
    margin-right: 8px;
}

button,
input,
.search-card {
    transition-timing-function: ease-in-out !important;
    transition-duration: 80ms !important;
}

.search-wrapper {
    padding: 0 15px;
    height: calc(100vh - 140px);
    overflow-y: auto;
    box-sizing: border-box;
    transition-timing-function: ease-in-out !important;
    transition-duration: 80ms !important;
    animation-duration: 80ms;
}

.ai-matching {
    &.search-wrapper {
        height: calc(100vh - 85px);
    }

    .view-project-sec {
        height: calc(100vh - 220px);
    }

    .filters-ai-match {
        height: calc(100vh - 80px);
    }
}

@media (max-width: 959px) {
    .ai-matching-filter-drawer {
        .filters--search {
            padding: 15px 15px 60px;
            box-sizing: border-box;
        }
    }
}
