.view-project-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 130px;
    box-sizing: border-box;
    position: relative;

    .search-card__person {
        padding: 15px 0 0px 20px;
    }

    .search-card__action-wrp {
        margin-left: auto;
        flex-wrap: nowrap;
        padding: 15px 20px 0 0;

        .search-card__more-btn {
            position: absolute;
            top: 20px;
            right: 15px;
        }
    }

    .MuiTabScrollButton-root {
        width: 20px;
    }

    .moved-time {
        position: absolute;
        top: 56px;
        right: 20px;
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        color: #aaa;
    }

    .preview-tabs-sec {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .candidate-sec-tabs {
            margin-top: 5px;
            padding: 0 5px;

            .MuiTab-root {
                font-size: 13px;
            }

            a {
                text-decoration: none;
            }
        }

        .notes {
            font-size: 13px;
            color: #aaaaaa;
            padding: 4px 15px 0 15px;
            font-weight: 500;
            cursor: pointer;
        }

        .active-notes {
            color: #7f56d9;
        }
    }
}

@media (max-width: 600px) {
    .view-project-header {
        .search-card__action-wrp {
            .search-card__more-btn {
                right: 2px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .detail-project-page-container {
        .stage-btn-name-hide {
            display: none;
        }
    }
}

.view-project-sec {
    padding: 0 20px 20px;
    height: calc(100vh - 273px);
    overflow-y: auto;
    box-sizing: border-box;

    .section-heading {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #111111;
        padding-top: 25px;
        margin-top: 0;
    }

    .spotlight {
        font-size: 13px;
        line-height: 150%;
        color: #111111;
        margin-top: 0;
        padding-top: 10px;

        .item {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            margin-right: 16px;
            line-height: 16px;
            background: #f5f5f5;
            border-radius: 4px;
            padding: 6px 12px;
            width: fit-content;
            margin-top: 7px;
            cursor: default;
            min-height: 36px;
            box-sizing: border-box;

            img,
            svg {
                vertical-align: middle;
                margin-right: 5px;
                font-size: 16px;
                width: 16px;
                height: 16px;
            }
        }

        .social-links {
            color: #111111;
            cursor: pointer;

            svg {
                margin-right: 0;
            }
        }

        .other-item {
            line-height: 180%;
            margin: 0;

            span {
                font-weight: 600;
            }
        }
    }

    .work-item {
        position: relative;
        font-size: 13px;
        line-height: 150%;
        color: #111111;
        margin-bottom: 15px;

        * {
            margin: 0;
        }

        .position {
            font-weight: 600;
            width: calc(100% - 140px);
        }

        .company {
            font-weight: 400;
        }

        .period {
            position: absolute;
            top: 0;
            right: 0;
            color: #999999;
            width: 140px;
            text-align: right;
        }

        .desc {
            margin-top: 10px;
            white-space: pre-line;
            overflow-wrap: break-word;
        }

        .readMoreText {
            display: block;
            font-weight: 500;
            color: $color7F56D9 !important;
        }
    }

    .edu-item {
        position: relative;
        font-size: 13px;
        line-height: 150%;
        color: #111111;
        margin-bottom: 15px;

        * {
            margin: 0;
        }

        .institute {
            font-weight: 600;
            width: calc(100% - 140px);
        }

        .qual {
            font-weight: 400;
        }

        .country {
            font-weight: 400;
        }

        .period {
            position: absolute;
            top: 0;
            right: 0;
            color: #999999;
            width: 140px;
            text-align: right;
        }
    }

    .sections {
        .location-item {
            display: flex;
            flex-direction: row;
            font-size: 13px;
            line-height: 16px;
            color: #111111;
            align-items: center;
            margin-bottom: 10px;

            * {
                margin: 0;
            }

            .country-flag {
                width: 40px;
                height: 40px;
                object-fit: cover;
                margin-right: 10px;
                border-radius: 50%;
            }

            .country-name {
                font-weight: 600;
            }
        }
    }

    .cus-chip {
        display: inline-flex;
        background: #f5f5f5;
        color: #111111;
        border-radius: 4px;
        font-size: 13px;
        margin: 5px 10px 5px 0;
    }

    .favourite-chips-wrapper {
        position: relative;
        display: inline-block;
        width: fit-content;

        .chip-favorite-indicator {
            position: absolute;
            top: -1px;
            right: 5px;
            width: 14px;
            height: 14px;
            fill: #7f56d9;

            path {
                stroke: #7f56d9;
            }
        }
    }

    .lang-chip {
        display: inline-block;
        background: #f5f5f5;
        color: #111111;
        border-radius: 4px;
        font-size: 13px;
        margin: 5px 10px 5px 0;
        padding: 6px 12px;
        text-align: center;

        .lang {
            margin: 0;
            font-size: 13px;
            line-height: 150%;
        }

        .level {
            margin: 0;
            color: #aaaaaa;
            line-height: 150%;
        }
    }
}

.danger {
    color: red;
}

.expand-screen-icon {
    margin-left: auto;
    vertical-align: middle;
    cursor: pointer;
    padding-right: 16px;
    top: 20px;
    right: 30px;
    position: absolute;
    color: #999999;
    width: 16px;
    height: 16px;
}

.preview-col-container {
    .candidate-profile-view-card {
        .MuiAvatar-root {
            width: 60px;
            height: 60px;
            font-size: 24px;
        }
    }
}

.candidate-profile-view-card {
    position: relative;
    border-radius: 8px !important;

    .MuiAvatar-root {
        font-size: 14px;
    }

    .experience-period {
        font-size: 13px;
        line-height: 150%;
        color: #999999;
        margin: 2px 0 0;
    }
}

.tag-icon {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
    margin-right: 3px;
    cursor: pointer;
}

.tool-tip-tag-div {
    box-sizing: border-box;

    .tooltip-header {
        display: flex;
        justify-content: space-between;
    }

    .tag-img {
        display: block;
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-right: 10px;
    }

    .heading {
        font-weight: 500;
        font-size: 13px;
        margin: 0;
        color: #ffffff;
    }

    .desc {
        font-size: 11px;
        color: #aaaaaa;
        margin: 0;
        margin-top: 10px;
    }
}

.search-card__action-wrp-group-btn {
    .btn {
        border-right: 0 !important;
        background-color: $color7F56D9 !important;
        font-size: 13px !important;
        font-weight: 500 !important;
        color: #ffffff !important;
    }

    .option {
        padding: 0 !important;
        min-width: 24px !important;
        background-color: $color7448d4 !important;
        font-size: 13px !important;
        font-weight: 500 !important;
        color: #ffffff !important;

        .MuiButton-startIcon {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
    }
}
