.testimonial-wrapper {
    height: 320px;

    .testimonial-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }

    .testimonial-desc {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: $color101828;
    }
}
