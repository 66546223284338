#project-list-table-container {
  max-height: calc(100vh - 370px);
}

// add edit modal
#add-edit-project-modal {
  .salary-range-wrapper {
    select {
      width: 110px;
    }
  }
}

.not-seen-projects-td {
  padding-left: 0;
  padding-right: 0 !important;

  .not-seen-projects {
    fill: $color7448d4;
    font-size: 8px;
  }
}

#project-video-info-modal {
  iframe {
    display: block;
    width: 100%;
    height: 315px;
  }

  .save-btn {
    width: auto;
    height: auto;
    white-space: pre-wrap !important;
  }
}

// project share
.project-share-list-wrapper {
  margin-top: 20px;

  .project-share-list-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    .image-title-sub-title-wrapper {
      margin-top: 0;
    }

    .role {
      font-size: 14px;
      line-height: 20px;
      color: $color667085;

      &.share-with-all-btn {
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .icon-comp {
      display: none;

      .close-item-btn {
        color: $colorF04438;
      }

      &.icon-comp-display {
        display: flex;
        align-items: center;
      }
    }

    &:hover {
      .role-member {
        display: none;
      }

      .icon-comp {
        display: block;
        .close-item-btn {
          padding: 10px;
          width: 16px;
          height: 16px;

          &:hover {
            background: $colorF9F5FF;
            border-radius: 8px;
            cursor: pointer;
          }
        }

        &.icon-comp-display {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

// add to project modal
.add-to-project-modal-dropdown-wrapper {
  display: block;
  padding: 5px 10px;
  cursor: pointer;

  .project-name {
    margin: 0;
    color: $color101828;
    font-size: 16px;
  }

  .company {
    margin: 0;
    color: $color9B9B9B;
    font-size: 14px;
  }

  &:hover {
    background: $colorF9FAFB;
  }
}

.all-projects-page {
  .stats-widget-container {
    .stats-widget-wrapper:first-child {
      width: 42%;
    }

    .stats-widget-wrapper:nth-child(2) {
      width: 58%;
    }
  }
}
