.upload-wrapper {
    position: relative;

    .upload-paper {
        position: relative;
        border-radius: 8px;
        border: 0;
    }

    .label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color344054;
        margin-bottom: 8px;
    }

    .photo-preview {
        position: relative;
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        border-radius: 50%;
        margin-bottom: 12px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
            background: $colorF9F5FF;
        }

        .close-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            background: $color6941C6;
            color: $colorFFFFFF;
            padding: 5px;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            cursor: pointer;
        }
    }

    .name-avatar {
        width: 50px;
        height: 50px;
        background: $colorF9FAFB;
        color: $color475467;
        font-weight: 500;
        margin-bottom: 12px;
    }

    .upload-box-inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        min-height: unset;
        padding: 16px;
        width: 100%;
        border: 2px dashed $colorEAECF0;
        box-sizing: border-box;
        border-radius: 8px;
        cursor: pointer;

        .modal-icon {
            display: flex;
            align-items: center;
            background: $colorF4EBFF;
            border: 8px solid $colorF9F5FF;
            color: $color7F56D9;
            padding: 8px;
            font-size: 20px;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            box-sizing: border-box;
            justify-content: center;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .file-name {
            font-size: 14px;
            margin-bottom: 3px;
            font-weight: 500;
            color: $color111111;
        }

        .upload-box-main-text {
            font-size: 14px;
            line-height: 20px;
            color: $color667085;

            span {
                font-weight: 500;
                color: $color6941C6;
            }
        }

        .upload-box-sub-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $color667085;
        }

        &:hover {
            background: $colorFCFAFF !important;
        }
    }
}
