.curation-in-progress-info-wrapper {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 428px;
    min-width: 300px;

    .curation-in-progress-icon {
        display: block;
        margin: auto;

        @media (max-width: 575px) {
            width: 150px;
        }
    }

    .curation-in-progress-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: $color000000;
        margin-top: 24px;
        margin-bottom: 8px;
    }

    .curation-in-progress-desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $color667085;
        margin-top: 0px;
        margin-bottom: 32px;
    }
}
