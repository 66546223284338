.table-list-and-footer-wrapper {
    background: $colorFFFFFF;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06) !important;

    .list-sticky-table-container {
        box-sizing: border-box;
        margin-top: 2px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .list-table {
            border-collapse: collapse;

            th {
                // text-transform: capitalize;
                background: $colorF9FAFB !important;
                height: 44px;
                padding: 0 24px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: $color667085;
                border-bottom: 1px solid $colorEAECF0;

                .MuiTableSortLabel-icon {
                    display: none;
                }

                span {
                    &:hover {
                        color: $color111111;
                    }
                }

                .MuiTableSortLabel-root {
                    &:hover {
                        .MuiTableSortLabel-icon {
                            display: block !important;
                        }
                    }
                }

                .MuiTableSortLabel-root.Mui-active {
                    .MuiTableSortLabel-icon {
                        display: block !important;
                    }
                }

                .table-sorting-wrapper {
                    svg {
                        display: flex;
                        align-items: center;
                        width: 16px;
                        height: 16px;

                        path {
                            stroke: currentColor;
                        }
                    }
                }
            }

            .status-wrapper {
                display: inline-flex;
                align-items: center;
                padding: 2px 8px;
                border-radius: 16px;
                font-weight: 500;
                gap: 5px;
                white-space: nowrap;

                svg {
                    font-size: 6px;
                }
            }

            .status-active {
                color: $color027A48;
                background: $colorECFDF3;

                svg {
                    circle {
                        fill: $color12B76A;
                    }
                }
            }

            .status-inactive {
                color: $colorB42318;
                background: $colorFEF3F2;

                svg {
                    circle {
                        fill: $colorF04438;
                    }
                }
            }

            .status-pending {
                color: $colorB54708;
                background: $colorFFFAEB;

                svg {
                    circle {
                        fill: $colorF79009;
                    }
                }
            }

            .status-placed {
                color: $color667085;
                background: $colorf2f2f3;

                svg {
                    circle {
                        fill: $colorAAAAAA;
                    }
                }
            }

            .status-pending-activation {
                color: $color1F32BB;
                background: $colorDEEAF9;

                svg {
                    circle {
                        fill: $color171291;
                    }
                }
            }

            tr {
                border-bottom: 1px solid $colorEAECF0;

                td {
                    min-height: 44px;
                    padding: 12px 24px;
                    font-size: 14px;
                    line-height: 20px;
                    color: $color667085;
                    border-bottom: unset;

                    .pulsating-circle {
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }

                    .td-title-text {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: $color101828;
                        text-transform: capitalize;
                        margin: 0;
                    }

                    .td-desc-text {
                        font-size: 14px;
                        line-height: 20px;
                        color: $color667085;
                        text-transform: capitalize;
                        margin: 0;
                    }
                }

                .tab {
                    padding: 0 15px;
                    text-align: center;
                    width: 80px;

                    &:hover {
                        background: $colorF9F5FF;
                        color: $color6941C6;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }

                .dot-action-menu {
                    visibility: hidden;
                }

                &:hover {
                    background: $colorF9FAFB !important;

                    .dot-action-menu {
                        visibility: visible;
                        border-radius: 4px;

                        &:hover {
                            background: $colorFFFFFF !important;
                        }
                    }
                }
            }

            .no-results-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                color: $color667085;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    .pagination-wrapper {
        position: sticky;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        bottom: 0;
        border-top: 1px solid $colorEAECF0 !important;
        padding: 12px 24px;
        box-sizing: border-box;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-top: -2px !important;

        button {
            color: $color667085;

            &:hover {
                background: $colorF9FAFB !important;
            }

            @media (max-width: 575px) {
                .MuiButton-startIcon {
                    margin-right: 0 !important;
                }

                .MuiButton-endIcon {
                    margin-left: 0 !important;
                }
            }
        }

        ul {
            .Mui-selected {
                background: $colorF9FAFB !important;
                color: $color1D2939;
            }
        }
    }
}
