.cus-navbar {
  background: $colorFFFFFF !important;
  box-shadow: none !important;
  border-bottom: 1px solid $colorEAECF0;
  height: 72px;

  .tool-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    .menu-wrapper {
      display: flex;
      align-items: center;

      .nav-bar-logo {
        display: flex;
        margin-right: 40px;
        justify-items: center;
      }
    }
  }

  .hamburger-menu-btn {
    padding: 0;
    margin-right: 12px;
  }

  .menus {
    display: flex;
    list-style: none;
    gap: 5px;
    padding-left: 0;

    .menu-items {
      position: relative;
      margin-top: 18px;
      padding-bottom: 18px;

      .nav-btn {
        color: $color344054;
        font-size: 16px;
        font-weight: 500;
        border: none;
        border-radius: 6px;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        padding: 8px 14px;

        &:hover {
          background: $colorF9F5FF;
          color: $color6941C6;
        }
      }

      .active {
        background: $colorF9F5FF;
        color: $color6941C6;
      }

      &:hover {
        .nav-btn {
          background: $colorF9F5FF;
          color: $color6941C6;
        }
      }
    }

    .dropdown {
      position: absolute;
      left: 0;
      right: auto;
      font-size: 0.875rem;
      z-index: 9999;
      padding: 0.5rem 0;
      list-style: none;
      background-color: #fff;
      display: none;
      border: 1px solid $colorF2F4F7;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 8px;
      min-width: 240px;
      margin: 0;
      margin-top: 18px;

      &.menu-account {
        left: auto;
        right: 0;
      }

      li,
      a {
        height: 40px;
        color: $color344054;

        .MuiListItemIcon-root {
          min-width: 30px !important;
          color: $color344054;

          svg {
            width: 16px;
            height: 16px;
          }
        }

        .MuiTypography-root {
          font-size: 14px !important;
          line-height: 20px;
        }

        &:hover {
          background: $colorF9FAFB;
        }
      }

      &.show {
        display: block;
      }
    }
  }
}

.navbar-mobile-menu {
  .MuiDrawer-paper {
    width: 100%;

    .header {
      position: sticky;
      top: 0;
      display: flex;
      padding: 20px;
      border-bottom: 1px solid $colorF2F4F7;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      background: $colorFFFFFF;
      z-index: 1;

      .close-btn {
        font-size: 22px;
        color: $color667085;
      }
    }

    .accordion-item {
      box-shadow: none !important;
      border: 0;

      .MuiAccordionSummary-root {
        padding-left: 20px !important;
        padding-right: 20px !important;
        color: $color344054 !important;
        font-weight: 500;
      }

      .MuiAccordionDetails-root {
        padding: 0 !important;
      }

      &::before {
        background-color: unset !important;
      }
    }

    .nav-links {
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $color344054;
      padding: 15px 20px;
      text-decoration: none;

      svg {
        width: 20px;
        height: 20px;
        color: $color7F56D9;
      }
    }

    .Mui-expanded {
      margin: 0 !important;
    }

    @media (min-width: 480px) {
      width: 300px;
    }
  }
}

.navbar-non-mobile-display {
  display: flex;
  gap: 5px;
}

@media (min-width: 769px) {
  .navbar-mobile-display {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .navbar-non-mobile-display {
    display: none !important;
  }
}

.menu-dropdown-wrapper {
  .MuiMenu-paper {
    top: 73px !important;
  }
}
