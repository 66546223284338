#active-credits-list-table {
    .credit-log-accordion {
        border: 0;

        .MuiAccordionSummary-content {
            &.Mui-expanded {
                margin: 8px;
            }
        }

        .accordion-header {
            & > div {
                display: flex;
                align-items: center;
            }

            .MuiAccordionSummary-expandIconWrapper {
                svg {
                    width: 20px;

                    path {
                        stroke: $color667085
                    }
                }
            }

            &:hover {
                background: $colorF9FAFB;
            }
        }

        .accordion-details-wrapper {
            margin-left: 75px;

            .accordion-row {
                display: flex;
                padding: 10px;
                width: fit-content;
                border-radius: 4px;
                cursor: pointer;

                div {
                    width: 135px;

                    &:last-child {
                        font-weight: 500;
                        width: 100px;
                    }
                }

                &.header {
                    color: $color667085;
                } 

                &.items {
                    &:hover {
                        background: $colorF9FAFB;
                    }
                }

            }
        }
    }
}


