$color000000: #000000;
$colorFFFFFF: #FFFFFF;
$colorF5F5F5: #F5F5F5;
$colorD0D5DD: #D0D5DD;
$color344054: #344054;
$color111111: #111111;
$colorE5E5E5: #E5E5E5;
$color667085: #667085;
$color6941C6: #6941C6;
$colorF9F5FF: #F9F5FF;
$colorF4EBFF: #F4EBFF;
$color7F56D9: #7F56D9;
$color7448d4: #7448d4;
$colorF9FAFB: #F9FAFB;
$colorF2F4F7: #F2F4F7;
$colorEAECF0: #EAECF0;
$color101828: #101828;
$colorc10000: #c10000;
$colorc85d47: #c85d47;
$color027A48: #027A48;
$colorECFDF3: #ECFDF3;
$colorF94444: #F94444;
$colorD63333: #D63333;
$colorB42318: #B42318;
$colorF2994A: #F2994A;
$colorB54708: #B54708;
$color1D2939: #1D2939;
$color475467: #475467;
$colorD7D7DD: #D7D7DD;
$colorAAAAAA: #AAAAAA;
$colorDDDDDD: #DDDDDD;
$colorE9E9F1: #E9E9F1;
$color9B9B9B: #9B9B9B;
$colorf2f2f3: #f2f2f3;
$colorFCFAFF: #FCFAFF;
$color12B76A: #12B76A;
$colorF79009: #F79009;
$colorF04438: #F04438;
$colorFFFAEB: #FFFAEB;
$colorFEF3F2: #FEF3F2;
$colorDB4E23: #DB4E23;
$colorFCECE8: #FCECE8;
$colorAD3713: #AD3713;
$color171291: #171291;
$colorDEEAF9: #DEEAF9;
$color1F32BB: #1F32BB;
$color412D6B: #412D6B;
$color98A2B3: #98A2B3;
$colorE9D7FE: #E9D7FE;
$colorFFFCF0: #FFFCF0;
$color3C50E0: #3C50E0
