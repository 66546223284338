.location-item {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 10px;
    
    .country-flag {
        display: inline;
        
        img {
            margin-right: 5px;
        }
    }

    &:hover {
        .chip-close {
            display: block;
        }
    }

    .drop-down-icon {
        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.MuiChip-root.grit-chip.new-grit-chip {
    margin-bottom: 0;
    min-height: 80px;
    background-color: #fff !important;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    width: 100%;
}

.chip-close {
    position: absolute;
    display: none;
    top: -10px;
    right: -12px;
    color: #ffffff;
    border-radius: 50%;
    padding: 4px;
    background: #761EE4;
    width: 16px;
    height: 16px;
    cursor: pointer;
    z-index: 1;
}

.new-grit-chip .skills__skill-chip:not(.filter-chip__inner) {
    display: block;
    white-space: normal;
    min-height: fit-content;
}

.MuiChip-root.grit-chip.new-grit-chip.candidate-view-location {
    color: black !important;

    .MuiListItemAvatar-root {
        min-width: 40px;
    }
}

.location-nested-select-layer-1 {
    overflow: auto;
}

.location-nested-select-layer-1 .MuiMenu-paper{
    margin-left: 25px;
    ul {
        overflow: auto;
        li {
            overflow: visible;
            font-size: 13px !important;
        }
    }
}

.location-nested-select-layer-2 {
    overflow: auto;
}

.location-nested-select-layer-2 .MuiMenu-paper {
    ul {
        overflow: auto;
        li {
            overflow: visible;
            font-size: 13px !important;
        }
    }
}

.candidate-view-location-text {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 7px;
}

.candidate-view-location-sub-text {
    color: black !important;
    margin: 0;
    font-size: 14px;
    line-height: 17px;
}

.location-box {
    padding: 22px 24px;
    margin-top: 10px;
    margin-bottom: 36px;
}

.profile-add-edit-container {
    padding: 0 !important;
}

.cv-preview-side-col {
    height: calc(100vh - 80px);
}

.profile-edit-side-col {
    height: calc(100vh - 80px);
    overflow-y: scroll;
    padding: 30px !important;
    box-sizing: border-box;
}
