.cus-alert-dialog-wrapper {
    padding: 32px;
    max-width: 400px;
    box-sizing: border-box;

    .alert-icon {
        svg {
            display: block;
            margin: auto;
            width: 50px;
            height: 50px;
        }

        &.success {
            color: $color12B76A;
        }

        &.error {
            color: $colorF04438;
        }

        &.warning {
            color: $colorF79009;
        }
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
    }

    .message {
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 30px;
    }
}
