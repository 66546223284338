.stats-widget-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 16px;
    max-height: 105px;

    .stats-widget-wrapper {
        position: relative;
        display: flex;
        background: $colorFFFFFF;
        border: 1px solid $colorEAECF0;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
        padding: 16px 20px 16px 170px;
        margin-bottom: 16px;
        box-sizing: border-box;

        .chart-wrapper {
            position: absolute;
            left: 10px;
            bottom: -1px;
            width: 150px;
            height: 76px;
            cursor: pointer;
        }

        .stats-items-wrapper {
            display: flex;
            align-content: center;
            justify-content: space-between;
            width: 98%;
            gap: 15px;

            .stat-item {
                .title {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: $color667085;
                    margin: 0;
                    white-space: nowrap;

                    @media (min-width: 993px) and (max-width: 1050px) {
                        font-size: 9px;
                        line-height: 15px;
                    }

                    @media (min-width: 1051px) and (max-width: 1100px) {
                        font-size: 11px;
                    }

                    @media (min-width: 1250px) and (max-width: 1300px) {
                        font-size: 12px;
                    }
                }

                .count {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    color: $color101828;
                    margin: 0;

                    @media (min-width: 993px) and (max-width: 1050px) {
                        font-size: 20px;
                    }
                }

                .sub-count {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: $color667085
                }
            }

            .stat-item:first-child {
                .title,
                .count {
                    color: $color7F56D9;
                }
            }
        }
    }

    @media (max-width: 992px) {
        display: none;
    }
}
