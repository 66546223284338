.tool-tip-pro-name-div {
    font-size: 13px;
    line-height: 150%;

    .heading {
        font-weight: 600;
        font-size: 16px;
        margin-top: 0;

        .tip-emoji-icon {
            width: 18px;
            vertical-align: top;
            margin-right: 5px;
        }
    }

    ul {
        padding-left: 25px;
    }
}

