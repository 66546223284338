.icon-comp {
  display: flex;
  align-items: center;

  svg path {
    stroke: currentColor;
  }

  span {
    display: flex;
    align-items: center;
  }

  &.remove-stroke {
    svg path {
      stroke: unset;
    }
  }
}

.icon-comp.btn-icon {
  svg {
    color: $color667085;
  }


  &:hover {
      cursor: pointer;

  }
}

.icon-comp.bulk-action-comp-icon {
  svg {
    width: 16px;
    height: 16px;
  }
}

.icon-comp.nav-bar-icon {
  svg {
    width: 16px;
    height: 16px;
  }
}

.icon-comp.table-pagination-btn-icon {
  svg {
    width: 20px;
    height: 20px;
  }
}

.icon-comp.add-another-user {
  svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.icon-comp.add-another-btn-icon {
  svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.icon-comp.remove-company-user {
  svg {
    display: block;
    width: 16px;
    height: 16px;
  }
}


.guaranteed-candidate-indicator {
  position: absolute;
  right: 0;
  bottom: -5px;
  background: #fbfbfb;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 2px;
  box-sizing: border-box;

  &.big {
    width: 26px;
    height: 26px;
  }

  svg path {
    stroke: $color7448d4;
  }
}
